import { Center, Loader } from '@mantine/core';
import { AuthLayout } from '../Layouts/AuthLayout.tsx';

export function Checking() {
    return (
        <AuthLayout>
            <Center>
                <Loader color="yellow" />
            </Center>
            );
        </AuthLayout>
    );
}
