import Session from 'supertokens-web-js/recipe/session';
import { useEffect } from 'react';
import { AuthLayout } from '../Layouts/AuthLayout.tsx';
import { Center } from '@mantine/core';
import { redirectToUrlOrLegacyHome } from './Logic/Rutas.ts';

async function attemptRefresh() {
    try {
        const success = await Session.attemptRefreshingSession();
        if (success) {
            const redirectBack =
                new URLSearchParams(window.location.search).get(
                    'redirectBack'
                ) ?? undefined;
            redirectToUrlOrLegacyHome(redirectBack);
        } else {
            window.location.href = '/login';
        }
    } catch {
        window.location.href = '/login';
    }
}

export function RefreshSession() {
    useEffect(() => {
        void (async () => {
            await attemptRefresh();
        })();
    }, []);
    return (
        <AuthLayout>
            <Center>Refrescando sesión. Espere unos instantes por favor</Center>
        </AuthLayout>
    );
}
