export const Env = {
    PACKAGE_VERSION: process.env.PACKAGE_VERSION,
    LEGACY_DOMAIN: import.meta.env.VITE_LEGACY_DOMAIN,
    GATEWAY_URL: import.meta.env.VITE_GATEWAY_URL,
    ST_API_DOMAIN: import.meta.env.VITE_ST_API_DOMAIN,
    ST_WEBSITE_DOMAIN: import.meta.env.VITE_ST_WEBSITE_DOMAIN,
    SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
    MOCK_AUTH: import.meta.env.VITE_MOCK_AUTH,
    VITE_CONFIG_CAT: import.meta.env.VITE_CONFIG_CAT,
    MODE: import.meta.env.MODE as 'development' | 'production',
    VITE_ZENRISE_ENV: import.meta.env.VITE_ZENRISE_ENV,
    USE_TOKEN_OR_COOKIES: import.meta.env.VITE_USE_TOKEN_OR_COOKIES as
        | 'token'
        | 'cookies',
    VITE_ST_COOKIE_DOMAIN: import.meta.env.VITE_ST_COOKIE_DOMAIN,
};
