import { submitNewPassword } from 'supertokens-web-js/recipe/emailpassword';
import { FieldError } from './Errors/FieldError.tsx';
import { ResetPasswordTokenNoValidoError } from './Errors/ResetPasswordTokenNoValidoError.tsx';
import { captureException } from '@sentry/react';
import { FetchError } from './Errors/FetchError.ts';

/**
 * https://app.swaggerhub.com/apis/supertokens/FDI/1.18.0#/EmailPassword%20Recipe/passwordReset
 * @param newPassword
 */
export async function changePasswordWithToken(newPassword: string) {
    let response: Awaited<ReturnType<typeof submitNewPassword>>;
    try {
        response = await submitNewPassword({
            formFields: [
                {
                    id: 'password',
                    value: newPassword,
                },
            ],
        });
    } catch (error) {
        console.log(error);
        captureException(error);
        throw new FetchError(error);
    }

    if (response.status === 'FIELD_ERROR') {
        throw new FieldError({
            field: 'email',
            fieldMessage: response.formFields[0].error,
        });
    } else if (response.status === 'RESET_PASSWORD_INVALID_TOKEN_ERROR') {
        throw new ResetPasswordTokenNoValidoError();
    }
}
