import { AccessTokenPayload } from '../Types/Varios.ts';
import { Checking } from './Checking.tsx';
import { PropsWithChildren, ReactNode } from 'react';
import { Forbidden } from './Forbidden.tsx';
import { getSessionContextFn } from './UseSessionContext.ts';
import { UserMetadataWrapper } from './UserMetadataWrapper.tsx';
import { configureSentryInfo } from '../Utils/Sentry.ts';
import debug from 'debug';

export interface CheckerProps extends PropsWithChildren {
    forbiddenComponent?: Element;
    checkingComponent?: Element;
    onlyAdmin?: boolean;
    onlyUser?: boolean;
}

const useSessionContext = getSessionContextFn();

const log = debug('auth');

export function Checker({
    checkingComponent,
    forbiddenComponent,
    onlyAdmin = false,
    onlyUser = false,
    children,
}: CheckerProps) {
    const checkingSession = useSessionContext();
    if (checkingSession.loading) {
        return (checkingComponent ?? <Checking />) as ReactNode;
    }

    if (!checkingSession.doesSessionExist) {
        //refresh forzado en vez de setLocation porque no anda...
        window.location.href = '/login';
        return null;
    }
    const payload = {
        ...checkingSession.accessTokenPayload,
        id: checkingSession.userId,
    } as AccessTokenPayload;
    log('%O', payload);

    //Tomo los datos que vienen del payload, menos jwt y se los paso a sentry
    configureSentryInfo(checkingSession.userId, {
        userInfo: {
            is_sadmin: payload.is_sadmin,
            legacy_id: payload.legacy_id,
            roles: 'roles' in payload ? payload.roles : [],
        },
    });

    if (onlyAdmin && !payload.is_sadmin) {
        return (forbiddenComponent ?? <Forbidden />) as ReactNode;
    }
    if (onlyUser && payload.is_sadmin) {
        return (forbiddenComponent ?? <Forbidden />) as ReactNode;
    }

    return (
        <UserMetadataWrapper payload={payload}>{children}</UserMetadataWrapper>
    );
}
