import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';
import { useEffect } from 'react';
import { useLocation } from 'wouter';
import { Center } from '@mantine/core';
import { AuthLayout } from '../Layouts/AuthLayout.tsx';

export function Logout() {
    const [, setLocation] = useLocation();
    useEffect(() => {
        void (async () => {
            await EmailPassword.signOut();
            setLocation('/login');
        })();
    }, [setLocation]);
    return (
        <AuthLayout>
            <Center>Cerrando sesión...</Center>
        </AuthLayout>
    );
}
