import { AuthLayout } from '../../Layouts/AuthLayout.tsx';
import {
    Alert,
    Button,
    Center,
    PasswordInput,
    Stack,
    TextInput,
} from '@mantine/core';
import { Colors } from '../../Utils/Colors.ts';
import { IconAlertCircle } from '@tabler/icons-react';
import { Values } from './MigracionHook.tsx';
import { FormErrors, useForm } from '@mantine/form';
import {
    validateEmail,
    validatePassword,
} from '../../Utils/BasicValidations.ts';
import { AlertGrande2 } from '../../Primitivas/AlertGrande.tsx';

export interface MigracionProps {
    initialValues?: Partial<Values>;
    nombre?: string;
    mostrarNombreTienda?: boolean;
    loading: boolean;
    errorMessage?: string;
    onSubmit: (values: Values) => void;
    emailSugerido?: string;
    formErrors?: FormErrors;
}

export function MigracionComponent({
    nombre,
    mostrarNombreTienda = false,
    loading,
    errorMessage,
    onSubmit,
    emailSugerido,
    initialValues,
    formErrors,
}: MigracionProps) {
    const form = useForm({
        initialValues: {
            email: emailSugerido ? decodeURIComponent(emailSugerido) : '',
            password: '',
            ...initialValues,
        },
        initialErrors: formErrors,
        validate: {
            email: validateEmail,
            password: validatePassword,
        },
    });
    return (
        <AuthLayout>
            <AlertGrande2 sinIcono>
                <Center
                    sx={{
                        width: '100%',
                        paddingBottom: 10,
                        paddingLeft: 10,
                        paddingRight: 10,
                    }}
                >
                    <div>
                        <h3>Bienvenido a la nueva consola</h3>
                        {/*Para continuar ingresá tu email y <br /> elegí una*/}
                        {/*contraseña.*/}
                        <h4>Paso 1 </h4>
                        <p style={{ textAlign: 'justify' }}>
                            Crear una cuenta en nuestro sistema de
                            autenticación. <br /> ✅ ¡Es fácil! Carga abajo un
                            mail y una clave para crear una cuenta y continuar
                            disfrutando de Luker.
                        </p>{' '}
                        <h4>Paso 2 </h4>
                        <p style={{ textAlign: 'justify' }}>
                            Suscribite haciendo clic en “contratar” y
                            posteriormente cargando los datos de pagos en el
                            nuevo sistema de cobranzas para mantener activo el
                            servicio de Luker en tu tienda. 💳{' '}
                        </p>{' '}
                        <p>
                            <strong>¡Eso es todo!</strong>
                        </p>
                    </div>
                </Center>
            </AlertGrande2>
            {errorMessage && (
                <Alert
                    mx={'1em'}
                    icon={<IconAlertCircle size="1rem" />}
                    title="Error"
                    color="red"
                >
                    {errorMessage}
                </Alert>
            )}
            <form onSubmit={form.onSubmit(onSubmit)}>
                <Stack>
                    {nombre && mostrarNombreTienda && (
                        <TextInput
                            size={'lg'}
                            label="Tienda"
                            placeholder=""
                            readOnly={true}
                            disabled={loading}
                            value={nombre}
                        />
                    )}

                    <TextInput
                        size={'lg'}
                        label="Email"
                        placeholder="Ingresá tu email"
                        required
                        disabled={loading}
                        {...form.getInputProps('email')}
                    />
                    <PasswordInput
                        size={'lg'}
                        label="Contraseña"
                        placeholder="Al menos 8 caracteres" //Al menos 8 caracteres
                        required
                        disabled={loading}
                        {...form.getInputProps('password')}
                    />
                    <Button
                        style={{
                            backgroundColor: Colors.azul,
                            // color: Colors.celeste,
                        }}
                        formNoValidate={true}
                        size={'lg'}
                        type={'submit'}
                        fullWidth
                        mt="xl"
                        disabled={loading}
                        loading={loading}
                    >
                        {loading ? 'Guardando...' : 'Confirmar'}
                    </Button>
                </Stack>
            </form>
        </AuthLayout>
    );
}
