import {
    Button,
    Center,
    createStyles,
    Flex,
    MantineSize,
    Modal,
} from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import { ReactNode } from 'react';

const useStyles = createStyles((theme) => {
    return {
        header: {
            backgroundColor: theme.colors.rojoLuker[3],
        },
        title: {
            color: 'white',
            fontWeight: 'bolder',
        },
        body: {
            padding: '1rem !important',
        },
    };
});

export function ErrorModal({
    title,
    message,
    opened,
    close,
    size,
    hideCloseButton,
    icon,
}: {
    title?: string;
    size?: MantineSize;
    message: string | ReactNode;
    opened: boolean;
    hideCloseButton?: boolean;
    close: () => void;
    icon?: ReactNode;
}) {
    const { classes } = useStyles();
    return (
        <Modal
            opened={opened}
            size={size ?? 'sm'}
            withCloseButton={false}
            onClose={close}
            title={
                <>
                    <Flex
                        align={'center'}
                        gap={10}
                    >
                        {icon ?? <IconAlertCircle />}
                        {title ?? 'Ha ocurrido un error'}
                    </Flex>
                </>
            }
            classNames={{
                header: classes.header,
                title: classes.title,
                body: classes.body,
            }}
        >
            <Center pt={10}>{message}</Center>
            {hideCloseButton ? null : (
                <Flex
                    justify={'flex-end'}
                    pt={30}
                >
                    <Button onClick={close}>Cerrar</Button>
                </Flex>
            )}
        </Modal>
    );
}
