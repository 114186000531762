import { useForm } from '@mantine/form';
import { validateEmail } from '../../Utils/BasicValidations.ts';
import { sendEmailResetPassword } from './Api/SendEmailResetPassword.tsx';
import { Values } from './ResetPasswordRequest.tsx';
import { useMutation } from '@tanstack/react-query';

export function ResetPasswordRequestHook() {
    const mutation = useMutation({
        mutationFn: sendEmailResetPassword,
    });

    function handle(values: Values) {
        mutation.mutate({ email: values.email });
    }
    const form = useForm<Values>({
        initialValues: {
            email: '',
        },
        validate: {
            email: validateEmail,
        },
    });

    return {
        form,
        handle,
        mutation,
    };
}
