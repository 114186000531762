import { useDisclosure } from '@mantine/hooks';
import {
    ApolloCache,
    DefaultContext,
    gql,
    MutationTuple,
    OperationVariables,
    useMutation,
    useQuery,
    useApolloClient,
} from '@apollo/client';
import cookie from 'cookie';
import { MainLayout } from '../../Layouts/MainLayout.tsx';
import { MenuAdmin } from '../../Layouts/MenuAdmin.tsx';
import { Panel } from '../../Primitivas/Panel.tsx';
import {
    Collapse,
    Group,
    Stack,
    Alert,
    Box,
    Button,
    Flex,
    Modal,
    Text,
    Textarea,
    Title,
} from '@mantine/core';
import {
    ResetDbDocument,
    TestCrearPlanInicialDocument,
} from '../../gql/graphql.ts';
import { JsonViewer } from '../Utils/JsonViewer.tsx';
import {
    GetEstadoCuentasEnAuthQuery,
    GetEstadoCuentasEnLegacyQuery,
    GetEstadoCuentasEnSuscripcionesQuery,
    GetProblemasQuery,
    RecibirIpnDocument,
    RecibirWebhookDocument,
} from '../../gql/graphql.ts';
import { MutationButton } from '../../Primitivas/MutationButton.tsx';
import { useState } from 'react';

export function AdminController() {
    const [jwtAbierto, jwtDisclosure] = useDisclosure(false);
    const client = useApolloClient();
    const cookies = cookie.parse(document.cookie);
    const parsed = JSON.parse(window.atob(cookies.sFrontToken)) as {
        up?: { jwt?: string };
    };
    const jwt =
        'up' in parsed && parsed.up && 'jwt' in parsed.up
            ? parsed.up.jwt
            : null;
    const resetCache = () => {
        void client.resetStore();
    };
    return (
        <MainLayout
            titulo={`Admin`}
            containerSize={'xl'}
            fluid={true}
            menu={<MenuAdmin />}
        >
            <Panel titulo={'Importantdo cuentas'}>
                <Group align={'flex-start'}>
                    <Stack>
                        <EstadoEnLegacy />
                        <EstadoEnSubs />
                        <EstadoEnAuth />
                    </Stack>
                    <Problemas />
                </Group>
            </Panel>
            <Panel titulo={'Acciones'}>
                <Flex
                    gap={10}
                    direction={'column'}
                >
                    <Row
                        buttonLabel={'Reset DB'}
                        desc={
                            'Borra todo y vuelve a crear la base. Mucho cuidado!!'
                        }
                        useMutationResult={useMutation(ResetDbDocument, {
                            onCompleted: resetCache,
                        })}
                        disabled={!import.meta.env.DEV}
                    />
                    <Row
                        buttonLabel={'Crear plan inicial'}
                        disabled={true}
                        desc={'Crea un plan si no existe ninguno'}
                        useMutationResult={useMutation(
                            TestCrearPlanInicialDocument,
                            { onCompleted: resetCache }
                        )}
                    />
                    <Row
                        buttonLabel={'Importar Legacy'}
                        desc={'...'}
                        useMutationResult={useMutation(
                            gql`
                                mutation testImportarCuentas {
                                    testImportarCuentas
                                }
                            `,
                            { onCompleted: resetCache }
                        )}
                    />
                    <Row
                        buttonLabel={'Importar Pagos de TN'}
                        desc={'...'}
                        useMutationResult={useMutation(
                            gql`
                                mutation testImportarPagos {
                                    testImportarPagos
                                }
                            `,
                            { onCompleted: resetCache }
                        )}
                        disabled={true}
                    />
                    <Row
                        buttonLabel={'Analizar Migración'}
                        desc={'...'}
                        useMutationResult={useMutation(
                            gql`
                                mutation analizarMigracion {
                                    analizarImportacion
                                }
                            `,
                            { onCompleted: resetCache }
                        )}
                        disabled={true}
                    />

                    <RecibirWebhookRow></RecibirWebhookRow>
                    <RecibirIPNRow></RecibirIPNRow>
                </Flex>
            </Panel>
            <Panel
                titulo={'JWT'}
                extras={
                    <Button onClick={jwtDisclosure.toggle}>
                        {jwtAbierto ? 'Ocultar' : 'Mostrar'}
                    </Button>
                }
            >
                {jwt && (
                    <Collapse in={jwtAbierto}>
                        <strong>sFrontToken:</strong> <br />
                        <JsonViewer value={jwt} />
                        <strong>Header 1:</strong>
                        <br />
                        <JsonViewer
                            value={{
                                Authorization: `Bearer ${jwt}`,
                            }}
                        />
                        <strong>Header 2:</strong>
                        <br />
                        <JsonViewer value={`Bearer ${jwt}`} />
                    </Collapse>
                )}
            </Panel>
        </MainLayout>
    );
}

function RecibirWebhookRow() {
    const [isOpen, { open, close }] = useDisclosure();
    const [data, setData] = useState<string>();
    const [recibirWebhook, opts] = useMutation(RecibirWebhookDocument);
    return (
        <Flex
            gap={30}
            align={'center'}
        >
            <Modal
                title={'Recibir webhook'}
                size={'xl'}
                opened={isOpen}
                onClose={close}
            >
                <Text>Pegue el body del webhook, en JSON o string</Text>
                {opts.error && (
                    <Alert>Ha ocurrido un error: {opts.error.message}</Alert>
                )}
                <Textarea
                    minRows={20}
                    // autosize={true}
                    style={{ minHeight: 300 }}
                    onChange={(evt) => setData(evt.currentTarget.value)}
                ></Textarea>
                <Button
                    loading={opts.loading}
                    onClick={(_evt) => {
                        if (!data) return;
                        // const content = data.startsWith('{')
                        //     ? JSON.stringify(data)
                        //     : data;
                        console.log('mandando', data);
                        void recibirWebhook({
                            variables: {
                                content: data,
                            },
                            onCompleted() {
                                close();
                            },
                        });
                    }}
                >
                    Recibir
                </Button>
            </Modal>
            <Button
                disabled={true}
                onClick={open}
            >
                Recibir webhook
            </Button>
            <Text></Text>
        </Flex>
    );
}
function RecibirIPNRow() {
    const [isOpen, { open, close }] = useDisclosure();
    const [data, setData] = useState<string>();
    const [recibirIPN, opts] = useMutation(RecibirIpnDocument);
    return (
        <Flex
            gap={30}
            align={'center'}
        >
            <Modal
                title={'Recibir IPN'}
                size={'xl'}
                opened={isOpen}
                onClose={close}
            >
                <Text>Pegue el body del webhook, en JSON o string</Text>
                {opts.error && (
                    <Alert>
                        Ha ocurrido un error: <pre>{opts.error.message}</pre>
                    </Alert>
                )}
                <Textarea
                    minRows={20}
                    // autosize={true}
                    style={{ minHeight: 300 }}
                    onChange={(evt) => setData(evt.currentTarget.value)}
                ></Textarea>
                <Button
                    loading={opts.loading}
                    onClick={(_evt) => {
                        if (!data) return;
                        // const content = data.startsWith('{')
                        //     ? JSON.stringify(data)
                        //     : data;
                        console.log('mandando', data);
                        void recibirIPN({
                            variables: {
                                content: data,
                            },
                            onCompleted() {
                                close();
                            },
                        });
                    }}
                >
                    Recibir
                </Button>
            </Modal>
            <Button
                disabled={true}
                onClick={open}
            >
                Recibir IPN
            </Button>
            <Text></Text>
        </Flex>
    );
}

function Row<
    TData = unknown,
    TVariables = OperationVariables,
    TContext = DefaultContext,
    TCache extends ApolloCache<unknown> = ApolloCache<unknown>
>({
    useMutationResult,
    buttonLabel,
    desc,
    disabled,
}: {
    useMutationResult: MutationTuple<TData, TVariables, TContext, TCache>;
    buttonLabel: string;
    desc: string;
    disabled?: boolean;
}) {
    return (
        <Flex
            gap={30}
            align={'center'}
        >
            <MutationButton
                //@ts-ignore
                useMutationResult={useMutationResult}
                disabled={disabled}
            >
                {buttonLabel}
            </MutationButton>
            <Text>{desc}</Text>
        </Flex>
    );
}

function EstadoEnSubs() {
    const { data, loading } =
        useQuery<GetEstadoCuentasEnSuscripcionesQuery>(gql`
            query getEstadoCuentasEnSuscripciones {
                estadoCuentasEnSuscripciones {
                    total
                    bloqueadas
                    no_bloqueadas
                    suscripcion_activa
                    trial
                    en_migracion
                }
            }
        `);
    if (loading) return 'cargando';
    const datos = data?.estadoCuentasEnSuscripciones;
    return (
        <ul>
            <li>
                En Subs: {datos?.total?.toLocaleString()}
                <ul>
                    <li>
                        por servicio:
                        <ul>
                            <li>
                                Bloqueadas:{' '}
                                {datos?.bloqueadas?.toLocaleString()}
                            </li>
                            <li>
                                No Bloqueadas:{' '}
                                {datos?.no_bloqueadas?.toLocaleString()}
                            </li>
                        </ul>
                    </li>
                </ul>
                <ul>
                    <li>
                        por estado:
                        <ul>
                            <li>
                                <strong>Activas</strong>{' '}
                                {datos?.suscripcion_activa?.toLocaleString()}
                                {/*<ul>*/}
                                {/*    <li>al día XXX</li>*/}
                                {/*    <li>con problemas de pagos XXX</li>*/}
                                {/*    <li>se le acaba a fin del periodo XXX</li>*/}
                                {/*</ul>*/}
                            </li>
                            <li>
                                <strong>En trial</strong>{' '}
                                {datos?.trial?.toLocaleString()}
                            </li>
                            <li>
                                <strong>En migración</strong>{' '}
                                {datos?.en_migracion?.toLocaleString()}
                            </li>
                        </ul>
                    </li>
                    {/*<li>Bloqueadas: XXX</li>*/}
                    {/*<li>Activas: XXX</li>*/}
                </ul>
            </li>
        </ul>
    );
}

function EstadoEnLegacy() {
    const { data, loading } = useQuery<GetEstadoCuentasEnLegacyQuery>(gql`
        query getEstadoCuentasEnLegacy {
            estadoCuentasEnLegacy {
                total
                bloqueadas
                no_bloqueadas
                sin_auth
            }
        }
    `);
    if (loading) return 'cargando';
    const datos = data?.estadoCuentasEnLegacy;
    return (
        <ul>
            <li>
                En Legacy: {datos?.total?.toLocaleString()}
                <ul>
                    <li>Bloqueadas: {datos?.bloqueadas?.toLocaleString()}</li>
                    <li>
                        No Bloqueadas: {datos?.no_bloqueadas?.toLocaleString()}
                    </li>
                    <li>
                        Sin Auth (pendientes de migrar):{' '}
                        {datos?.sin_auth?.toLocaleString()}
                    </li>
                </ul>
            </li>
        </ul>
    );
}

function EstadoEnAuth() {
    const { data, loading } = useQuery<GetEstadoCuentasEnAuthQuery>(gql`
        query getEstadoCuentasEnAuth {
            estadoCuentasEnAuth
        }
    `);
    if (loading) return 'cargando';
    const datos = data?.estadoCuentasEnAuth as unknown as {
        cuentas: string;
        usuarios: string;
    };
    const cuentas = datos?.cuentas
        ? Number.parseInt(datos?.cuentas).toLocaleString()
        : 'ERROR';
    const usuarios = datos?.usuarios
        ? Number.parseInt(datos?.usuarios).toLocaleString()
        : 'ERROR';
    return (
        <ul>
            <li>
                En Auth:{' '}
                <ul>
                    <li>Cuentas: {cuentas}</li>
                    <li>Usuarios: {usuarios}</li>
                </ul>
            </li>
        </ul>
    );
}

function Problemas() {
    const { data, loading } = useQuery<GetProblemasQuery>(gql`
        query getProblemas {
            problemas {
                tiendasSinEmailDesdeLegacy
            }
        }
    `);
    if (loading) return 'Cargando...';
    return (
        <Box>
            <Title order={2}>Problemas</Title>
            <ul>
                <li>
                    Tiendas sin email desde legacy:{' '}
                    {data?.problemas.tiendasSinEmailDesdeLegacy.toLocaleString() ??
                        'UNKNOWN'}
                </li>
            </ul>
        </Box>
    );
}
