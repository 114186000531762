import { CrearUsuarioHook } from './CrearUsuarioHook.tsx';
import { CrearUsuarioComponent } from './CrearUsuarioComponent.tsx';
import { SimpleError } from '../SimpleError.tsx';

export function CrearUsuarioController() {
    const searchParams = new URLSearchParams(document.location.search);
    const cid = searchParams.get('cid');
    const uid = searchParams.get('uid');
    const nombre = searchParams.get('nombre') ?? undefined;
    const emailSugerido = searchParams.get('email') ?? undefined;
    const returnTo = searchParams.get('returnTo') ?? undefined;

    if (!cid || !uid) {
        return <SimpleError errorMessage={'Ha ocurrido un error'} />;
    }

    return (
        <CrearUsuarioComponent
            {...CrearUsuarioHook({
                cid,
                uid,
                returnTo,
            })}
            emailSugerido={emailSugerido}
            nombre={nombre}
        />
    );
}
