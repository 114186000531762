import { PropsWithChildren } from 'react';
import { Center, Group, MantineColor, useMantineTheme } from '@mantine/core';
import { IconAlertHexagonFilled } from '@tabler/icons-react';

export function AlertGrande(
    props: PropsWithChildren<{ color?: MantineColor }>
) {
    const { colors } = useMantineTheme();
    return (
        <Center>
            <div
                style={{
                    position: 'relative',
                    paddingBottom: 30,
                }}
            >
                <IconAlertHexagonFilled
                    size={60}
                    style={{
                        color: props.color ?? colors.celesteLuker[4],
                        position: 'absolute',
                        left: -80,
                        top: 20,
                    }}
                />
                {props.children}
            </div>
        </Center>
    );
}

/**
 * Temporario
 * TODO: tengo que hacer uno de estos bien hecho
 * @param props
 * @constructor
 */
export function AlertGrande2(
    props: PropsWithChildren<{ color?: MantineColor; sinIcono?: boolean }>
) {
    const { colors } = useMantineTheme();
    return (
        <Group
            position={'left'}
            sx={{
                borderRadius: 5,
                padding: 5,
                backgroundColor: colors.celesteLuker[1],
                marginBottom: 30,
                textAlign: 'center',
            }}
        >
            {!props.sinIcono && (
                <IconAlertHexagonFilled
                    size={60}
                    style={{
                        color: props.color ?? colors.celesteLuker[4],
                        // position: 'absolute',
                        // left: -80,
                        // top: 20,
                    }}
                />
            )}
            {props.children}
        </Group>
    );
}
