import { createStyles, getStylesRef } from '@mantine/core';

export const useMenuStyles = createStyles((theme) => {
    return {
        menuItemRoot: {
            ['&.active']: {
                [`& .${getStylesRef('menuItemLabel')}`]: {
                    color: `${theme.colors.doradoLuker[4]} !important`,
                    // color: `${theme.colors.doradoLuker[4]}`,
                },
                [`& .${getStylesRef('menuItemIcon')}`]: {
                    ['& path']: {
                        fill: `${theme.colors.doradoLuker[4]} !important`,
                        // fill: `${theme.colors.doradoLuker[4]}`,
                    },
                },
            },
            [`&:hover`]: {
                backgroundColor: 'inherit',
            },
            [`&:hover .${getStylesRef('menuItemLabel')}`]: {
                color: 'white',
                fontWeight: 800,
            },
            [`&:hover .${getStylesRef('menuItemIcon')}`]: {
                ['& path']: {
                    fill: 'white',
                },
            },
            // padding: '10px 25px',
            margin: '10px 0 ',
        },
        menuItemBody: {},
        menuItemIcon: {
            ref: getStylesRef('menuItemIcon'),
            ['& svg']: {
                width: 24,
                height: 25,
            },
            ['& path']: {
                fill: theme.colors.celesteLuker[1],
                stroke: theme.colors.celesteLuker[1],
            },
        },
        menuItemLabel: {
            // backgroundColor: '#384FA1',
            // color: 'red',// assign reference to selector
            ref: getStylesRef('menuItemLabel'),
            color: theme.colors.celesteLuker[1],
            fontSize: '12px',
            fontWeight: 'bold',
            lineHeight: '30px',
            // ...(theme.fn.hover({
            //     color: theme.colors.celesteLuker[9],
            // }) as CSSObject),
            // '&:hover': {
            //     color: theme.colors.celesteLuker[9],
            // },
        },
    };
});
