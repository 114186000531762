import { sendPasswordResetEmail } from 'supertokens-web-js/recipe/emailpassword';
import { FieldError } from './Errors/FieldError.tsx';
import { FetchError } from './Errors/FetchError.ts';
import { captureException } from '@sentry/react';

/**
 * @see https://app.swaggerhub.com/apis/supertokens/FDI/1.18.0#/EmailPassword%20Recipe/passwordResetToken
 * @param email
 */
export async function sendEmailResetPassword({ email }: { email: string }) {
    let response: Awaited<ReturnType<typeof sendPasswordResetEmail>>;
    try {
        response = await sendPasswordResetEmail({
            formFields: [
                {
                    id: 'email',
                    value: email,
                },
            ],
        });
    } catch (error) {
        captureException(error);
        throw new FetchError(error);
    }

    if (response.status === 'FIELD_ERROR') {
        const fieldEmail = response.formFields[0];
        throw new FieldError({
            field: 'email',
            fieldMessage: fieldEmail.error,
        });
    }
}
