import { Component } from 'react';
import { Box, Image } from '@mantine/core';

export class Logo extends Component {
    render() {
        return (
            <Box
                mb={'1em'}
                w={'70%'}
                mx={'auto'}
                className={'Logo'}
            >
                <Image
                    src="/logoCompleto.svg"
                    alt=""
                />
            </Box>
        );
    }
}

export class LogoPequenio extends Component {
    render() {
        return (
            <Box
                // mb={'1em'}
                // w={'70%'}
                // mx={'auto'}
                className={'LogoPequenio'}
            >
                <Image
                    sx={{ height: '37px' }}
                    src="/logoCompleto.svg"
                    alt=""
                />
            </Box>
        );
    }
}
