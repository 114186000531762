import { useDocumentTitle } from '@mantine/hooks';
import { useState } from 'react';
import { FormErrors } from '@mantine/form';
import { CreateUser } from '../Logic/ST/CreateUser.ts';
import {
    redirectToUrlOrLegacyHome,
    redirectToUsuarioCreado,
} from '../Logic/Rutas.ts';

export interface Values {
    email: string;
    password: string;
}

export interface CrearUsuarioHookProps {
    cid: string;
    uid: string;
    returnTo?: string;
}

export interface CrearUsuarioHookReturn {
    loading: boolean;
    onSubmit: (values: Values) => void;
    errorMessage?: string;
    formErrors: FormErrors;
}

export function CrearUsuarioHook({
    cid,
    uid,
    returnTo,
}: CrearUsuarioHookProps): CrearUsuarioHookReturn {
    useDocumentTitle('Alta - Luker.io');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [loading, setLoading] = useState<boolean>(false);

    return {
        errorMessage,
        loading,
        formErrors,
        //TODO: hay que buscarle la vuelta a esto
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit: async (values) => {
            setLoading(true);
            setErrorMessage('');
            setFormErrors({});
            try {
                const resp = await CreateUser({
                    email: values.email,
                    password: values.password,
                    cid,
                    uid,
                    is_admin: false,
                });

                switch (resp.status) {
                    case 'ok': {
                        if (returnTo) {
                            //OJO! confiamos que desde el back se pase una url que sea un "migrated"
                            redirectToUrlOrLegacyHome(returnTo);
                        } else {
                            redirectToUsuarioCreado(values.email);
                        }
                        return;
                    }
                    case 'error': {
                        setLoading(false);
                        setFormErrors(resp.formErrors);
                        break;
                    }
                    default: {
                        setLoading(false);
                        setErrorMessage('Ha ocurrido un error');
                        break;
                    }
                }
            } catch (error: unknown) {
                console.error(error);
                setLoading(false);
                // if (err.isSuperTokensGeneralError === true) {
                //     // this may be a custom error message sent from the API by you.
                // }
                if (error instanceof Error) {
                    setErrorMessage(`Ha ocurrido un error: ${error.message}`);
                } else {
                    setErrorMessage(`Ha ocurrido un error`);
                }
            }
        },
    };
}
