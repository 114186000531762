import { Checker } from '../../Auth/Checker.tsx';
import { useLayoutEffect } from 'react';
import { AdminController } from './AdminController.tsx';

export function AdminPage() {
    useLayoutEffect(() => {
        document.title = 'Admin - Luker.io - Consola';
    }, []);
    return (
        <Checker onlyAdmin>
            <AdminController />
        </Checker>
    );
}
