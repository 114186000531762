/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
    T extends { [key: string]: unknown },
    K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
    | T
    | {
          [P in keyof T]?: P extends ' $fragmentName' | '__typename'
              ? T[P]
              : never;
      };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
    Date: { input: string; output: string };
    /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
    DateTime: { input: any; output: any };
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSON: { input: string; output: string };
    /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSONObject: { input: any; output: any };
};

export type AuditLog = {
    __typename?: 'AuditLog';
    comments?: Maybe<Scalars['String']['output']>;
    created_at?: Maybe<Scalars['DateTime']['output']>;
    cuenta_id?: Maybe<Scalars['String']['output']>;
    data?: Maybe<Scalars['JSONObject']['output']>;
    es_admin: Scalars['Boolean']['output'];
    es_auto: Scalars['Boolean']['output'];
    id: Scalars['String']['output'];
    key: Scalars['String']['output'];
    user_id?: Maybe<Scalars['String']['output']>;
    usuario?: Maybe<Usuario>;
};

export type Bloqueo = {
    __typename?: 'Bloqueo';
    bloqueada_event_id?: Maybe<Scalars['String']['output']>;
    bloqueada_mail_id?: Maybe<Scalars['String']['output']>;
    cuenta?: Maybe<Cuenta>;
    cuenta_id: Scalars['String']['output'];
    desbloqueada_event_id?: Maybe<Scalars['String']['output']>;
    desbloqueada_mail_id?: Maybe<Scalars['String']['output']>;
    fecha_bloqueo: Scalars['Date']['output'];
    fecha_desbloqueo?: Maybe<Scalars['Date']['output']>;
    id: Scalars['String']['output'];
    motivo: BloqueoMotivos;
    ultimo?: Maybe<Scalars['Boolean']['output']>;
};

export enum BloqueoMotivos {
    BloqueoManual = 'BLOQUEO_MANUAL',
    CanceladaPorCliente = 'CANCELADA_POR_CLIENTE',
    CanceladaPorPagoRechazado = 'CANCELADA_POR_PAGO_RECHAZADO',
    FaltaPago = 'FALTA_PAGO',
    FinTrial = 'FIN_TRIAL',
    MigracionCanceladaPorNosotros = 'MIGRACION_CANCELADA_POR_NOSOTROS',
}

export type CambiarDatosFacturacionParams = {
    condicion_ante_iva?: InputMaybe<Scalars['String']['input']>;
    cuenta_id: Scalars['String']['input'];
    documento?: InputMaybe<Scalars['String']['input']>;
    domicilio?: InputMaybe<Scalars['String']['input']>;
    razon_social?: InputMaybe<Scalars['String']['input']>;
    tipo_doc?: InputMaybe<Scalars['String']['input']>;
};

export type CambiarPlanPreContratacionParams = {
    cuenta_id: Scalars['String']['input'];
    nuevo_plan_id: Scalars['String']['input'];
};

export type CambiarPlanPreContratacionResponse = {
    __typename?: 'CambiarPlanPreContratacionResponse';
    cuenta_id: Scalars['String']['output'];
    plan_id: Scalars['String']['output'];
};

export type Contratacion = {
    __typename?: 'Contratacion';
    created_at?: Maybe<Scalars['DateTime']['output']>;
    cuenta?: Maybe<Cuenta>;
    cuenta_id: Scalars['String']['output'];
    estado?: Maybe<EstadoContratacionZenRise>;
    id: Scalars['String']['output'];
    plan?: Maybe<Plan>;
    plan_id?: Maybe<Scalars['String']['output']>;
    updated_at?: Maybe<Scalars['DateTime']['output']>;
    zenrise_id?: Maybe<Scalars['String']['output']>;
};

export type ContratacionesQueryParams = {
    after?: InputMaybe<Scalars['String']['input']>;
    filterConSuscripcion?: InputMaybe<Scalars['Boolean']['input']>;
    firsts?: InputMaybe<Scalars['Int']['input']>;
    ids?: InputMaybe<Array<Scalars['String']['input']>>;
    incluyeViejas?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContratarZenriseParams = {
    cuenta_id: Scalars['String']['input'];
    dni: Scalars['String']['input'];
    es_tarjeta_credito: Scalars['Boolean']['input'];
    nombre: Scalars['String']['input'];
    plan_id: Scalars['String']['input'];
    token: Scalars['JSON']['input'];
};

export type ContratarZenriseResponse = {
    __typename?: 'ContratarZenriseResponse';
    mensaje_error?: Maybe<Scalars['String']['output']>;
    ok: Scalars['Boolean']['output'];
};

export type CrearCuentaManualmenteParams = {
    cid: Scalars['String']['input'];
    cuentaId: Scalars['String']['input'];
    nombre: Scalars['String']['input'];
};

export type CrearUsuarioManualmenteParams = {
    cid: Scalars['String']['input'];
    cuentaId: Scalars['String']['input'];
    email: Scalars['String']['input'];
    password: Scalars['String']['input'];
    uid: Scalars['String']['input'];
    usuarioId: Scalars['String']['input'];
};

export type Cuenta = {
    __typename?: 'Cuenta';
    auditLogs: Array<AuditLog>;
    bloqueada?: Maybe<Scalars['Boolean']['output']>;
    bloqueoActivo?: Maybe<Bloqueo>;
    bloqueo_fecha?: Maybe<Scalars['Date']['output']>;
    bloqueo_razon?: Maybe<BloqueoMotivos>;
    bloqueos: Array<Bloqueo>;
    condicion_ante_iva?: Maybe<Scalars['String']['output']>;
    contratacionActiva?: Maybe<Contratacion>;
    datos_desde_tn?: Maybe<Scalars['JSON']['output']>;
    datos_desde_tn_traidos?: Maybe<Scalars['Date']['output']>;
    documento?: Maybe<Scalars['String']['output']>;
    domicilio?: Maybe<Scalars['String']['output']>;
    email_desde_legacy?: Maybe<Scalars['String']['output']>;
    email_pagos?: Maybe<Scalars['String']['output']>;
    etiquetas?: Maybe<Array<Scalars['String']['output']>>;
    id: Scalars['String']['output'];
    invoices?: Maybe<Array<Maybe<InvoiceZenRise>>>;
    legacy_id?: Maybe<Scalars['String']['output']>;
    migracion?: Maybe<Migracion>;
    nombre?: Maybe<Scalars['String']['output']>;
    pagos?: Maybe<Array<PagoTn>>;
    pais?: Maybe<Scalars['String']['output']>;
    razon_social?: Maybe<Scalars['String']['output']>;
    roles?: Maybe<Array<Maybe<Rol>>>;
    sparkline?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
    suscripcionActiva?: Maybe<Suscripcion>;
    tienda_url?: Maybe<Scalars['String']['output']>;
    tipo_doc?: Maybe<Scalars['String']['output']>;
    tn_store_id?: Maybe<Scalars['String']['output']>;
    trial?: Maybe<Trial>;
    trial_id?: Maybe<Scalars['String']['output']>;
    uso?: Maybe<Uso>;
};

export type CuentaEnAuth = {
    __typename?: 'CuentaEnAuth';
    cid?: Maybe<Scalars['String']['output']>;
    createdAt?: Maybe<Scalars['Date']['output']>;
    createdByUserId?: Maybe<Scalars['String']['output']>;
    fecha_creacion?: Maybe<Scalars['Date']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    nombre?: Maybe<Scalars['String']['output']>;
    roles?: Maybe<Array<Maybe<Rol>>>;
    updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type CuentaEnLegacy = {
    __typename?: 'CuentaEnLegacy';
    /** El id de usuario en auth, pero como lo tiene legacy */
    auth_id?: Maybe<Scalars['String']['output']>;
    bloqueo_event_id?: Maybe<Scalars['String']['output']>;
    bloqueo_fecha?: Maybe<Scalars['String']['output']>;
    cid?: Maybe<Scalars['String']['output']>;
    datos_desde_tn?: Maybe<Scalars['String']['output']>;
    datos_desde_tn_ultimo_update?: Maybe<Scalars['String']['output']>;
    email?: Maybe<Scalars['String']['output']>;
    estadisticas?: Maybe<Estadistica>;
    estado?: Maybe<Scalars['String']['output']>;
    estado_auth?: Maybe<Scalars['String']['output']>;
    estado_tn?: Maybe<Scalars['String']['output']>;
    fecha_migracion_usuario?: Maybe<Scalars['String']['output']>;
    /** El nuevo id */
    id?: Maybe<Scalars['String']['output']>;
    legacy_id?: Maybe<Scalars['String']['output']>;
    nombre_en_legacy?: Maybe<Scalars['String']['output']>;
    /** alias de tn_store_id */
    sid: Scalars['String']['output'];
    site_name?: Maybe<Scalars['String']['output']>;
    site_url?: Maybe<Scalars['String']['output']>;
    tn_plan?: Maybe<Scalars['String']['output']>;
    tn_store_name?: Maybe<Scalars['String']['output']>;
    /** El id de usuario en legacy */
    user_id?: Maybe<Scalars['String']['output']>;
};

export type CuentaEnLegacyEstadisticasArgs = {
    periodo: PeriodosEstadistica;
};

export type CuentaFiltros = {
    /** true solo blockeadas, falso solo no blockeadas */
    bloqueadas?: InputMaybe<Scalars['Boolean']['input']>;
    etiquetas?: InputMaybe<Array<Scalars['String']['input']>>;
    /**
     * Busca en:
     * legacy_id
     * tn_store_id
     * nombre
     * documento
     * razon_social
     * email_pagos
     * email_desde_legacy
     * tienda_url
     */
    search?: InputMaybe<Scalars['String']['input']>;
};

export type CuentasInputParams = {
    after?: InputMaybe<Scalars['String']['input']>;
    firsts?: InputMaybe<Scalars['Int']['input']>;
    ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CuentasQueryParams = {
    after?: InputMaybe<Scalars['String']['input']>;
    filtros?: InputMaybe<CuentaFiltros>;
    firsts?: InputMaybe<Scalars['Int']['input']>;
    ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardSuscripciones = {
    __typename?: 'DashboardSuscripciones';
    ClientesActivos?: Maybe<Scalars['String']['output']>;
    EnPlanes100?: Maybe<Scalars['String']['output']>;
    Porcentaje?: Maybe<Scalars['String']['output']>;
    TicketPromedio?: Maybe<Scalars['String']['output']>;
    falta?: Maybe<Scalars['String']['output']>;
    mensual?: Maybe<Scalars['String']['output']>;
    objetivo?: Maybe<Scalars['String']['output']>;
};

export type DataLoaderParams = {
    ids: Array<Scalars['String']['input']>;
};

export type Estadistica = {
    __typename?: 'Estadistica';
    clicks?: Maybe<Scalars['String']['output']>;
    ctr?: Maybe<Scalars['String']['output']>;
    datos?: Maybe<Array<Maybe<EstadisticaDatos>>>;
    desde?: Maybe<Scalars['String']['output']>;
    dias_para_atras?: Maybe<Scalars['String']['output']>;
    hovers?: Maybe<Scalars['String']['output']>;
    impresiones?: Maybe<Scalars['String']['output']>;
    periodo?: Maybe<Scalars['String']['output']>;
    ratio_interaccion?: Maybe<Scalars['String']['output']>;
    visitantes?: Maybe<Scalars['String']['output']>;
    visitantes_que_interactuaron?: Maybe<Scalars['String']['output']>;
};

export type EstadisticaDatos = {
    __typename?: 'EstadisticaDatos';
    clicks?: Maybe<Scalars['Int']['output']>;
    fecha?: Maybe<Scalars['String']['output']>;
    hovers?: Maybe<Scalars['Int']['output']>;
    impresiones?: Maybe<Scalars['Int']['output']>;
};

export enum EstadoContratacionZenRise {
    CrearSuscriptorZenriseError = 'CREAR_SUSCRIPTOR_ZENRISE_ERROR',
    Desestimada = 'DESESTIMADA',
    Desestimando = 'DESESTIMANDO',
    DesestimandoError = 'DESESTIMANDO_ERROR',
    EsperandoConfirmacionPago = 'ESPERANDO_CONFIRMACION_PAGO',
    EsperandoConfirmacionPagoError = 'ESPERANDO_CONFIRMACION_PAGO_ERROR',
    FinalizarError = 'FINALIZAR_ERROR',
    Inicial = 'INICIAL',
    PagoRechazado = 'PAGO_RECHAZADO',
    PendienteIngresarMetodoPago = 'PENDIENTE_INGRESAR_METODO_PAGO',
    ProcesandoConfirmacionPagoError = 'PROCESANDO_CONFIRMACION_PAGO_ERROR',
    Terminada = 'TERMINADA',
}

export type EstadoCuentasEnLegacy = {
    __typename?: 'EstadoCuentasEnLegacy';
    bloqueadas?: Maybe<Scalars['Int']['output']>;
    no_bloqueadas?: Maybe<Scalars['Int']['output']>;
    sin_auth?: Maybe<Scalars['Int']['output']>;
    total?: Maybe<Scalars['Int']['output']>;
};

export type EstadoCuentasEnSubs = {
    __typename?: 'EstadoCuentasEnSubs';
    bloqueadas?: Maybe<Scalars['Int']['output']>;
    en_migracion?: Maybe<Scalars['Int']['output']>;
    no_bloqueadas?: Maybe<Scalars['Int']['output']>;
    suscripcion_activa?: Maybe<Scalars['Int']['output']>;
    total?: Maybe<Scalars['Int']['output']>;
    trial?: Maybe<Scalars['Int']['output']>;
};

export enum EstadoIpn {
    PendienteProcesar = 'PENDIENTE_PROCESAR',
    Procesado = 'PROCESADO',
}

export enum EstadoMigracion {
    Cancelada = 'CANCELADA',
    CancelandoError = 'CANCELANDO_ERROR',
    EnGestionManual = 'EN_GESTION_MANUAL',
    Importada = 'IMPORTADA',
    ListaParaQueContrate = 'LISTA_PARA_QUE_CONTRATE',
    PreparandoParaContratacionError = 'PREPARANDO_PARA_CONTRATACION_ERROR',
}

export enum EstadoSuscripcion {
    Activa = 'ACTIVA',
    Activando = 'ACTIVANDO',
    ActivandoError = 'ACTIVANDO_ERROR',
    Cancelada = 'CANCELADA',
    CanceladaConServicioHastaFinPeriodoPagado = 'CANCELADA_CON_SERVICIO_HASTA_FIN_PERIODO_PAGADO',
    CancelandoEnPasarela = 'CANCELANDO_EN_PASARELA',
    CancelandoPorFaltaPago = 'CANCELANDO_POR_FALTA_PAGO',
    Desestimada = 'DESESTIMADA',
    ErrorCancelandoPorFaltaPago = 'ERROR_CANCELANDO_POR_FALTA_PAGO',
    ErrorCancelarEnPasarela = 'ERROR_CANCELAR_EN_PASARELA',
    MigracionPendientePrimerPago = 'MIGRACION_PENDIENTE_PRIMER_PAGO',
    PendienteAvisarCancelacionInmediataZenrise = 'PENDIENTE_AVISAR_CANCELACION_INMEDIATA_ZENRISE',
    PendienteAvisarCancelacionInmediataZenriseError = 'PENDIENTE_AVISAR_CANCELACION_INMEDIATA_ZENRISE_ERROR',
    PendientePrimerPago = 'PENDIENTE_PRIMER_PAGO',
}

export enum EstadoTrial {
    Activo = 'ACTIVO',
    Terminado = 'TERMINADO',
}

export enum EstadoWebhook {
    PendienteProcesar = 'PENDIENTE_PROCESAR',
    Procesado = 'PROCESADO',
}

export type ExtenderTrialParams = {
    comentario?: InputMaybe<Scalars['String']['input']>;
    cuenta_id: Scalars['String']['input'];
    nueva_fecha_fin: Scalars['String']['input'];
};

export type GenericInputParams = {
    after?: InputMaybe<Scalars['String']['input']>;
    afterr?: InputMaybe<Scalars['String']['input']>;
    firsts?: InputMaybe<Scalars['Int']['input']>;
    ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Ipn = {
    __typename?: 'IPN';
    body: Scalars['JSON']['output'];
    created_at: Scalars['DateTime']['output'];
    estado: EstadoIpn;
    externalReference: Scalars['String']['output'];
    id: Scalars['String']['output'];
    invoiceId: Scalars['Int']['output'];
    resultado_mensaje?: Maybe<Scalars['String']['output']>;
    resultado_ok?: Maybe<Scalars['Boolean']['output']>;
    transactionId: Scalars['String']['output'];
    updated_at: Scalars['DateTime']['output'];
};

export type IpnParams = {
    after?: InputMaybe<Scalars['String']['input']>;
    filterByCuentaId?: InputMaybe<Scalars['String']['input']>;
    firsts?: InputMaybe<Scalars['Int']['input']>;
    ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IniciarContratacionZenriseParams = {
    contratacion_id: Scalars['String']['input'];
    cuenta_id: Scalars['String']['input'];
};

export type IniciarContratacionZenriseResponse = {
    __typename?: 'IniciarContratacionZenriseResponse';
    mensaje_error?: Maybe<Scalars['String']['output']>;
    ok: Scalars['Boolean']['output'];
};

export type InputCancelarParams = {
    cuenta_id: Scalars['String']['input'];
    motivo_codigo?: InputMaybe<Scalars['String']['input']>;
    motivo_comentarios?: InputMaybe<Scalars['String']['input']>;
    motivo_texto?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceZenRise = {
    __typename?: 'InvoiceZenRise';
    created_at?: Maybe<Scalars['Date']['output']>;
    cuenta?: Maybe<Cuenta>;
    cuenta_id?: Maybe<Scalars['String']['output']>;
    estado_zenrise?: Maybe<Scalars['String']['output']>;
    facturada_manual?: Maybe<Scalars['Boolean']['output']>;
    facturada_manual_ruta?: Maybe<Scalars['String']['output']>;
    fecha_emision?: Maybe<Scalars['String']['output']>;
    fecha_estimado_acreditacion?: Maybe<Scalars['String']['output']>;
    fecha_pago?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    metodo_pago?: Maybe<Scalars['String']['output']>;
    monto?: Maybe<Scalars['Int']['output']>;
    numero_factura_propia?: Maybe<Scalars['String']['output']>;
    updated_at?: Maybe<Scalars['Date']['output']>;
    zenrise_id?: Maybe<Scalars['String']['output']>;
};

export type Migracion = {
    __typename?: 'Migracion';
    analisis_comentario?: Maybe<Scalars['String']['output']>;
    analisis_fecha?: Maybe<Scalars['Date']['output']>;
    analisis_problema?: Maybe<Scalars['String']['output']>;
    analisis_prox_pago?: Maybe<Scalars['Date']['output']>;
    analisis_resultado?: Maybe<Scalars['Boolean']['output']>;
    analisis_ult_pago?: Maybe<Scalars['Date']['output']>;
    analisis_ult_pago_dias?: Maybe<Scalars['Int']['output']>;
    analisis_ult_pago_monto?: Maybe<Scalars['Int']['output']>;
    creada_en_legacy?: Maybe<Scalars['Date']['output']>;
    cuenta?: Maybe<Cuenta>;
    cuenta_id: Scalars['String']['output'];
    estado?: Maybe<EstadoMigracion>;
    id: Scalars['String']['output'];
    json_importacion?: Maybe<Scalars['JSONObject']['output']>;
    legacy_estado?: Maybe<Scalars['String']['output']>;
    legacy_estado_tn?: Maybe<Scalars['String']['output']>;
    legacy_id?: Maybe<Scalars['String']['output']>;
    legacy_plan?: Maybe<Scalars['String']['output']>;
    suscripcion_id?: Maybe<Scalars['String']['output']>;
    tn_store_id?: Maybe<Scalars['String']['output']>;
};

export type MigracionesQueryParams = {
    after?: InputMaybe<Scalars['String']['input']>;
    estados?: InputMaybe<Array<EstadoMigracion>>;
    etiquetas?: InputMaybe<Array<Scalars['String']['input']>>;
    firsts?: InputMaybe<Scalars['Int']['input']>;
    /**
     * Busca en:
     * legacy_id
     * tn_store_id
     * nombre
     * documento
     * razon_social
     * email_pagos
     * email_desde_legacy
     * tienda_url
     */
    search?: InputMaybe<Scalars['String']['input']>;
    skipActivas?: InputMaybe<Scalars['Boolean']['input']>;
    skipBloqueadas?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Mutation = {
    __typename?: 'Mutation';
    UpdateInfoDesdeTn?: Maybe<Scalars['Boolean']['output']>;
    analizarImportacion: Scalars['JSONObject']['output'];
    analizarProblemaCuentas: Scalars['JSON']['output'];
    bloquearCuentasPorFinTrial?: Maybe<Scalars['Boolean']['output']>;
    bloquearSuscripcionesPorFaltaDePago?: Maybe<Scalars['Boolean']['output']>;
    /** Recordar que hay que hacer signOut después de llamar a esta mutation */
    cambiarClave?: Maybe<Scalars['Boolean']['output']>;
    cambiarDatosFacturacion?: Maybe<Cuenta>;
    /** Recordar que hay que hacer signOut después de llamar a esta mutation */
    cambiarEmail?: Maybe<Scalars['Boolean']['output']>;
    cambiarPlanPreContratacion: CambiarPlanPreContratacionResponse;
    cancelacionInmediataPorFaltaDePago: Scalars['Boolean']['output'];
    cancelar: Scalars['Boolean']['output'];
    confirmarPagoManual?: Maybe<Scalars['Boolean']['output']>;
    contratarZenrise: ContratarZenriseResponse;
    crearCuentaManualmente?: Maybe<CuentaEnAuth>;
    /** @deprecated No longer supported */
    crearPlanInicial: Scalars['String']['output'];
    crearSuscripcionPagoManual?: Maybe<Scalars['Boolean']['output']>;
    crearUsuarioManualmente?: Maybe<Usuario>;
    cuentasEnLimbo?: Maybe<Array<Maybe<Cuenta>>>;
    desestimarContratacion: Scalars['Boolean']['output'];
    desestimarSubscripcionesViejas?: Maybe<Array<Maybe<Suscripcion>>>;
    dispararEventoCuentaRefresh?: Maybe<Scalars['String']['output']>;
    elegirPlan?: Maybe<Scalars['Boolean']['output']>;
    empezarProcesoTerminarMigracion?: Maybe<Scalars['Boolean']['output']>;
    extenderTrial: Scalars['Boolean']['output'];
    fixContratacionesContext?: Maybe<Scalars['JSON']['output']>;
    fixCuentasSinNombre?: Maybe<Scalars['JSON']['output']>;
    fixDatosDesdeLegacy?: Maybe<Scalars['Boolean']['output']>;
    fixDatosDesdeLegacyEnMasa?: Maybe<Scalars['Boolean']['output']>;
    fixMigracionesContext?: Maybe<Scalars['JSON']['output']>;
    fixSuscripcionesContext?: Maybe<Scalars['JSON']['output']>;
    fixSuscripcionesDatosPlan?: Maybe<Scalars['JSON']['output']>;
    fixTrialsContext?: Maybe<Scalars['JSON']['output']>;
    fixUserMetadata?: Maybe<Scalars['JSON']['output']>;
    fixUsuario?: Maybe<Scalars['JSON']['output']>;
    /** Esto es para cuando la suscripción queda en ERROR_CANCELAR_EN_PASARELA pero se canceló bien en zenrise, sólo hay que mover la FSM */
    forzarCancelacion: Scalars['Boolean']['output'];
    forzarClave?: Maybe<Scalars['Boolean']['output']>;
    importarCuentaLegacy?: Maybe<Scalars['JSON']['output']>;
    importarCuentasLegacyConEtiqueta?: Maybe<Scalars['JSON']['output']>;
    importarEstadisticas?: Maybe<Scalars['Boolean']['output']>;
    importarInvoicesFromApiLogs?: Maybe<Scalars['Boolean']['output']>;
    iniciarContratacionZenrise: Scalars['String']['output'];
    mandarPlanZenRise: Scalars['String']['output'];
    migracionCancelar: Scalars['Boolean']['output'];
    migracionMarcarComoLista: Scalars['Boolean']['output'];
    migracionPasarGestionManual: Scalars['Boolean']['output'];
    procesarIPN: ProcesarIpnResultado;
    procesarWebhook: ProcesarWebhookResultado;
    procesoTerminarMigracionPaso1?: Maybe<Scalars['Boolean']['output']>;
    procesoTerminarMigracionPaso2?: Maybe<Scalars['Boolean']['output']>;
    procesoTerminarMigracionPaso3?: Maybe<Scalars['Boolean']['output']>;
    reemplazarCuentaId: Scalars['Boolean']['output'];
    resetDb: Scalars['Boolean']['output'];
    terminarTrials?: Maybe<Scalars['Boolean']['output']>;
    testBloquearCuenta?: Maybe<Scalars['Boolean']['output']>;
    /** @deprecated No longer supported */
    testCrearCuentaEnTrial: Scalars['String']['output'];
    testDesbloquearCuenta?: Maybe<Scalars['Boolean']['output']>;
    testEmail?: Maybe<Scalars['JSON']['output']>;
    testImportarCuentas?: Maybe<Scalars['Boolean']['output']>;
    /** @deprecated No longer supported */
    testImportarPagos?: Maybe<Scalars['Boolean']['output']>;
    /** @deprecated No longer supported */
    testSimularIPNRecibido: Scalars['Boolean']['output'];
    /** @deprecated No longer supported */
    testSimularWebhookRecibido: Scalars['Boolean']['output'];
    testSlackMessage?: Maybe<Scalars['Boolean']['output']>;
    traerDatosDesdeLegacyYGuardarLocal?: Maybe<Scalars['JSON']['output']>;
};

export type MutationUpdateInfoDesdeTnArgs = {
    id?: InputMaybe<Scalars['String']['input']>;
};

export type MutationBloquearCuentasPorFinTrialArgs = {
    fecha: Scalars['String']['input'];
    send_email_at?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationBloquearSuscripcionesPorFaltaDePagoArgs = {
    fecha: Scalars['String']['input'];
    send_email_at?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCambiarClaveArgs = {
    new_password: Scalars['String']['input'];
    old_password: Scalars['String']['input'];
};

export type MutationCambiarDatosFacturacionArgs = {
    params: CambiarDatosFacturacionParams;
};

export type MutationCambiarEmailArgs = {
    new_email: Scalars['String']['input'];
};

export type MutationCambiarPlanPreContratacionArgs = {
    params: CambiarPlanPreContratacionParams;
};

export type MutationCancelacionInmediataPorFaltaDePagoArgs = {
    cuenta_id: Scalars['String']['input'];
    skipEmail?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCancelarArgs = {
    params: InputCancelarParams;
};

export type MutationConfirmarPagoManualArgs = {
    cuenta_id: Scalars['String']['input'];
    fecha_proximo_pago: Scalars['String']['input'];
    fecha_ultimo_pago: Scalars['String']['input'];
    pasarela_id?: InputMaybe<Scalars['String']['input']>;
};

export type MutationContratarZenriseArgs = {
    params: ContratarZenriseParams;
};

export type MutationCrearCuentaManualmenteArgs = {
    params: CrearCuentaManualmenteParams;
};

export type MutationCrearPlanInicialArgs = {
    plan_id?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCrearSuscripcionPagoManualArgs = {
    cuenta_id: Scalars['String']['input'];
    fecha_creacion?: InputMaybe<Scalars['String']['input']>;
    pasarela?: InputMaybe<Pasarela>;
    plan_id: Scalars['String']['input'];
};

export type MutationCrearUsuarioManualmenteArgs = {
    params: CrearUsuarioManualmenteParams;
};

export type MutationCuentasEnLimboArgs = {
    do_it?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationDesestimarContratacionArgs = {
    cuenta_id: Scalars['String']['input'];
};

export type MutationDesestimarSubscripcionesViejasArgs = {
    fecha: Scalars['String']['input'];
};

export type MutationDispararEventoCuentaRefreshArgs = {
    cuenta_id: Scalars['String']['input'];
};

export type MutationElegirPlanArgs = {
    cuenta_id: Scalars['String']['input'];
    plan_id: Scalars['String']['input'];
};

export type MutationExtenderTrialArgs = {
    params: ExtenderTrialParams;
};

export type MutationFixCuentasSinNombreArgs = {
    do_id?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationFixDatosDesdeLegacyArgs = {
    id: Scalars['String']['input'];
};

export type MutationFixSuscripcionesDatosPlanArgs = {
    do_it?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationFixUserMetadataArgs = {
    fix?: InputMaybe<Scalars['Boolean']['input']>;
    st_id: Scalars['String']['input'];
};

export type MutationFixUsuarioArgs = {
    do_it?: InputMaybe<Scalars['Boolean']['input']>;
    st_id: Scalars['String']['input'];
};

export type MutationForzarCancelacionArgs = {
    cuenta_id: Scalars['String']['input'];
};

export type MutationForzarClaveArgs = {
    new_password: Scalars['String']['input'];
    usuario_id: Scalars['String']['input'];
};

export type MutationImportarCuentaLegacyArgs = {
    cid: Scalars['String']['input'];
    crear_cuenta_en_auth?: InputMaybe<Scalars['Boolean']['input']>;
    crear_cuenta_en_subs?: InputMaybe<Scalars['Boolean']['input']>;
    refrescar_cuenta?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationImportarCuentasLegacyConEtiquetaArgs = {
    cids: Array<Scalars['String']['input']>;
    etiqueta: Scalars['String']['input'];
    por_pasada?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationIniciarContratacionZenriseArgs = {
    params: IniciarContratacionZenriseParams;
};

export type MutationMandarPlanZenRiseArgs = {
    plan_id: Scalars['String']['input'];
};

export type MutationMigracionCancelarArgs = {
    comentario: Scalars['String']['input'];
    cuenta_id: Scalars['String']['input'];
};

export type MutationMigracionMarcarComoListaArgs = {
    cuenta_id: Scalars['String']['input'];
    fecha_proximo_pago: Scalars['Date']['input'];
};

export type MutationMigracionPasarGestionManualArgs = {
    cuenta_id: Scalars['String']['input'];
};

export type MutationProcesarIpnArgs = {
    id: Scalars['String']['input'];
};

export type MutationProcesarWebhookArgs = {
    id: Scalars['String']['input'];
};

export type MutationProcesoTerminarMigracionPaso1Args = {
    cantidad?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationProcesoTerminarMigracionPaso2Args = {
    cantidad?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationProcesoTerminarMigracionPaso3Args = {
    cantidad?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationReemplazarCuentaIdArgs = {
    cuenta_id_nuevo: Scalars['String']['input'];
    cuenta_id_original: Scalars['String']['input'];
};

export type MutationTerminarTrialsArgs = {
    fecha: Scalars['String']['input'];
};

export type MutationTestBloquearCuentaArgs = {
    cuenta_id: Scalars['String']['input'];
};

export type MutationTestCrearCuentaEnTrialArgs = {
    dias: Scalars['Int']['input'];
};

export type MutationTestDesbloquearCuentaArgs = {
    cuenta_id: Scalars['String']['input'];
};

export type MutationTestSimularIpnRecibidoArgs = {
    content: Scalars['String']['input'];
};

export type MutationTestSimularWebhookRecibidoArgs = {
    content: Scalars['String']['input'];
};

export type MutationTestSlackMessageArgs = {
    message: Scalars['String']['input'];
};

export type PagoTn = {
    __typename?: 'PagoTN';
    dias: Scalars['Int']['output'];
    fecha_pago: Scalars['Date']['output'];
    import_id: Scalars['String']['output'];
    moneda: Scalars['String']['output'];
    monto: Scalars['Int']['output'];
    tn_store_id: Scalars['String']['output'];
};

export enum Pasarela {
    Manual = 'MANUAL',
    Zenrise = 'ZENRISE',
}

export enum PeriodosEstadistica {
    Mes = 'mes',
    Semana = 'semana',
    TresMeses = 'tres_meses',
}

export type Plan = {
    __typename?: 'Plan';
    id: Scalars['String']['output'];
    limite?: Maybe<Scalars['Int']['output']>;
    mostrar_en_selector?: Maybe<Scalars['Boolean']['output']>;
    nombre_privado: Scalars['String']['output'];
    nombre_publico: Scalars['String']['output'];
    por_defecto?: Maybe<Scalars['Boolean']['output']>;
    precio: Scalars['Float']['output'];
    precio_con_iva: Scalars['Float']['output'];
    valido_desde?: Maybe<Scalars['String']['output']>;
    valido_hasta?: Maybe<Scalars['String']['output']>;
    zenrise_id?: Maybe<Scalars['String']['output']>;
};

export type Problemas = {
    __typename?: 'Problemas';
    tiendasSinEmailDesdeLegacy: Scalars['Int']['output'];
};

export type ProcesarIpnResultado = {
    __typename?: 'ProcesarIPNResultado';
    mensaje?: Maybe<Scalars['String']['output']>;
    ok: Scalars['Boolean']['output'];
};

export type ProcesarWebhookResultado = {
    __typename?: 'ProcesarWebhookResultado';
    mensaje?: Maybe<Scalars['String']['output']>;
    ok: Scalars['Boolean']['output'];
};

export type Query = {
    __typename?: 'Query';
    auditLogs: Array<AuditLog>;
    bloqueos: Array<Bloqueo>;
    contrataciones: Array<Contratacion>;
    cuenta?: Maybe<Cuenta>;
    cuentaEnAuth?: Maybe<CuentaEnAuth>;
    cuentaEnAuthParaMigracion?: Maybe<CuentaEnAuth>;
    cuentaEnLegacy?: Maybe<CuentaEnLegacy>;
    cuentaMerge?: Maybe<CuentaEnLegacy>;
    cuentaPorCid?: Maybe<Cuenta>;
    cuentas: Array<Cuenta>;
    cuentasEnAuth: Array<CuentaEnAuth>;
    cuentasEnMigracionParaAvisar?: Maybe<Array<Maybe<Cuenta>>>;
    cuentasMerge?: Maybe<Array<Maybe<CuentaEnLegacy>>>;
    dashboardSuscripciones?: Maybe<DashboardSuscripciones>;
    echo?: Maybe<Scalars['JSON']['output']>;
    estadisticasAdmin?: Maybe<Scalars['JSON']['output']>;
    estadoCuentasEnAuth?: Maybe<Scalars['JSON']['output']>;
    estadoCuentasEnLegacy?: Maybe<EstadoCuentasEnLegacy>;
    estadoCuentasEnSuscripciones?: Maybe<EstadoCuentasEnSubs>;
    facturacion_proximos_dias?: Maybe<ResultadoFacturacion>;
    getPlanesParaCuenta?: Maybe<Array<Maybe<Plan>>>;
    healthCheckSubscriptions: Scalars['Boolean']['output'];
    invoicesZenRise?: Maybe<Array<Maybe<InvoiceZenRise>>>;
    ipns: Array<Ipn>;
    migraciones: Array<Migracion>;
    now: Scalars['DateTime']['output'];
    plan?: Maybe<Plan>;
    planPorDefecto?: Maybe<Plan>;
    planes: Array<Plan>;
    problemas: Problemas;
    problems?: Maybe<Scalars['JSON']['output']>;
    procesoTerminarMigracion?: Maybe<
        Array<Maybe<RegistroProcesoTerminarMigracion>>
    >;
    reporteCuentasActivasConPagosAtrasados?: Maybe<Scalars['JSON']['output']>;
    suscripciones: Array<Suscripcion>;
    suscripcionesParaBloquear?: Maybe<Array<Maybe<Suscripcion>>>;
    suscripcionesParaDesestimar?: Maybe<Array<Maybe<Suscripcion>>>;
    testInterService?: Maybe<Scalars['JSON']['output']>;
    today: Scalars['Date']['output'];
    trials: Array<Trial>;
    trialsParaBloquear?: Maybe<Array<Maybe<Trial>>>;
    trialsParaTerminar?: Maybe<Array<Maybe<Trial>>>;
    usuario?: Maybe<Usuario>;
    usuarioByST?: Maybe<Usuario>;
    usuarios?: Maybe<Array<Maybe<Usuario>>>;
    usuariosDataLoader?: Maybe<Array<Maybe<Usuario>>>;
    /**
     * Sirve para unificar en el gateway, trae los usuarios en formato dataloader
     * usando el id en ST como key
     */
    usuariosPorSTDataLoader?: Maybe<Array<Maybe<Usuario>>>;
    webhooks: Array<Webhook>;
    zenrise_logs: Array<ZenRiseApiLog>;
};

export type QueryAuditLogsArgs = {
    params: GenericInputParams;
};

export type QueryBloqueosArgs = {
    params: GenericInputParams;
};

export type QueryContratacionesArgs = {
    params: ContratacionesQueryParams;
};

export type QueryCuentaArgs = {
    id: Scalars['String']['input'];
};

export type QueryCuentaEnAuthArgs = {
    id: Scalars['String']['input'];
};

export type QueryCuentaEnAuthParaMigracionArgs = {
    cid: Scalars['String']['input'];
};

export type QueryCuentaEnLegacyArgs = {
    cid?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCuentaMergeArgs = {
    id: Scalars['String']['input'];
};

export type QueryCuentaPorCidArgs = {
    cid: Scalars['String']['input'];
};

export type QueryCuentasArgs = {
    params: CuentasQueryParams;
};

export type QueryCuentasEnAuthArgs = {
    params: CuentasInputParams;
};

export type QueryCuentasEnMigracionParaAvisarArgs = {
    fecha: Scalars['String']['input'];
};

export type QueryCuentasMergeArgs = {
    ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryEchoArgs = {
    input?: InputMaybe<Scalars['JSON']['input']>;
};

export type QueryEstadisticasAdminArgs = {
    dias?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryFacturacion_Proximos_DiasArgs = {
    dias?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetPlanesParaCuentaArgs = {
    cuenta_id: Scalars['String']['input'];
};

export type QueryIpnsArgs = {
    params: IpnParams;
};

export type QueryMigracionesArgs = {
    params: MigracionesQueryParams;
};

export type QueryPlanArgs = {
    id: Scalars['String']['input'];
};

export type QuerySuscripcionesArgs = {
    params: SuscripcionesQueryParams;
};

export type QuerySuscripcionesParaBloquearArgs = {
    fecha: Scalars['String']['input'];
};

export type QuerySuscripcionesParaDesestimarArgs = {
    fecha: Scalars['String']['input'];
};

export type QueryTrialsArgs = {
    params: TrialsQueryParams;
};

export type QueryTrialsParaBloquearArgs = {
    fecha: Scalars['String']['input'];
};

export type QueryTrialsParaTerminarArgs = {
    fecha: Scalars['String']['input'];
};

export type QueryUsuarioArgs = {
    id: Scalars['String']['input'];
};

export type QueryUsuarioByStArgs = {
    stUserId: Scalars['String']['input'];
};

export type QueryUsuariosArgs = {
    params: UsuariosInputParams;
};

export type QueryUsuariosDataLoaderArgs = {
    input: DataLoaderParams;
};

export type QueryUsuariosPorStDataLoaderArgs = {
    input: DataLoaderParams;
};

export type QueryWebhooksArgs = {
    params: WebhookQueryParams;
};

export type QueryZenrise_LogsArgs = {
    cuenta_id?: InputMaybe<Scalars['String']['input']>;
    ultimos?: InputMaybe<Scalars['Int']['input']>;
};

export type RegistroProcesoTerminarMigracion = {
    __typename?: 'RegistroProcesoTerminarMigracion';
    cuenta?: Maybe<Cuenta>;
    cuenta_id: Scalars['String']['output'];
    email_enviado_sendgrid_id?: Maybe<Scalars['String']['output']>;
    erroed?: Maybe<Scalars['String']['output']>;
    fecha_creacion?: Maybe<Scalars['Date']['output']>;
    fecha_migracion_cancelada?: Maybe<Scalars['Date']['output']>;
    id: Scalars['String']['output'];
    usuario_temporal_email?: Maybe<Scalars['String']['output']>;
    usuario_temporal_password?: Maybe<Scalars['String']['output']>;
    usuario_tiene_al_comenzar?: Maybe<Scalars['Boolean']['output']>;
};

export type ReporteProcesoDiario = {
    __typename?: 'ReporteProcesoDiario';
    data: Scalars['JSON']['output'];
    fecha: Scalars['Date']['output'];
    fue_ejecutado: Scalars['Boolean']['output'];
    id: Scalars['String']['output'];
};

export type ResultadoFacturacion = {
    __typename?: 'ResultadoFacturacion';
    dias: Scalars['Int']['output'];
    total: Scalars['Float']['output'];
};

export type Rol = {
    __typename?: 'Rol';
    createdAt?: Maybe<Scalars['Date']['output']>;
    cuenta?: Maybe<CuentaEnAuth>;
    cuentaId?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    rolNombre?: Maybe<Scalars['String']['output']>;
    updatedAt?: Maybe<Scalars['Date']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
    usuario?: Maybe<Usuario>;
};

export type Suscripcion = {
    __typename?: 'Suscripcion';
    contratacion?: Maybe<Contratacion>;
    contratacion_id?: Maybe<Scalars['String']['output']>;
    cuenta?: Maybe<Cuenta>;
    cuenta_id: Scalars['String']['output'];
    dia_cobro?: Maybe<Scalars['Int']['output']>;
    es_manual?: Maybe<Scalars['Boolean']['output']>;
    estado: EstadoSuscripcion;
    fecha_creacion: Scalars['String']['output'];
    fecha_proximo_pago?: Maybe<Scalars['String']['output']>;
    fecha_ultimo_pago?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    /** @deprecated sacar de cuenta */
    legacy_id?: Maybe<Scalars['String']['output']>;
    /** @deprecated No longer supported */
    migrada_desde_legacy?: Maybe<Scalars['Boolean']['output']>;
    pasarela?: Maybe<Scalars['String']['output']>;
    pasarela_id?: Maybe<Scalars['String']['output']>;
    plan?: Maybe<Plan>;
    plan_id?: Maybe<Scalars['String']['output']>;
    source_active?: Maybe<Scalars['Boolean']['output']>;
    source_link?: Maybe<Scalars['String']['output']>;
    /** @deprecated use legacy_id */
    tn_store_id?: Maybe<Scalars['String']['output']>;
    uso?: Maybe<Uso>;
};

export type SuscripcionesQueryParams = {
    after?: InputMaybe<Scalars['String']['input']>;
    estados?: InputMaybe<Array<EstadoSuscripcion>>;
    firsts?: InputMaybe<Scalars['Int']['input']>;
    ids?: InputMaybe<Array<Scalars['String']['input']>>;
    incluyeViejas?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Trial = {
    __typename?: 'Trial';
    cuenta?: Maybe<Cuenta>;
    cuenta_id: Scalars['String']['output'];
    estado: EstadoTrial;
    fecha_creacion: Scalars['Date']['output'];
    fecha_fin: Scalars['Date']['output'];
    id: Scalars['String']['output'];
};

export type TrialsQueryParams = {
    firsts?: InputMaybe<Scalars['Int']['input']>;
    ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Uso = {
    __typename?: 'Uso';
    dias?: Maybe<Scalars['Int']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    notificaciones?: Maybe<Scalars['Int']['output']>;
    proyectado?: Maybe<Scalars['Int']['output']>;
};

export type Usuario = {
    __typename?: 'Usuario';
    cid?: Maybe<Scalars['String']['output']>;
    createdAt?: Maybe<Scalars['Date']['output']>;
    email?: Maybe<Scalars['String']['output']>;
    fecha_creacion_manual?: Maybe<Scalars['Date']['output']>;
    id: Scalars['String']['output'];
    isSuperAdmin?: Maybe<Scalars['Boolean']['output']>;
    password_temporal?: Maybe<Scalars['String']['output']>;
    password_temporal_pendiente_resetear?: Maybe<Scalars['Boolean']['output']>;
    roles?: Maybe<Array<Maybe<Rol>>>;
    stUserId: Scalars['String']['output'];
    uid?: Maybe<Scalars['String']['output']>;
    updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type UsuarioEnSt = {
    __typename?: 'UsuarioEnST';
    email?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    metadata?: Maybe<Scalars['JSON']['output']>;
    timeJoined?: Maybe<Scalars['JSON']['output']>;
};

export type UsuariosInputParams = {
    after?: InputMaybe<Scalars['String']['input']>;
    firsts?: InputMaybe<Scalars['Int']['input']>;
    ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Webhook = {
    __typename?: 'Webhook';
    body: Scalars['JSONObject']['output'];
    created_at: Scalars['DateTime']['output'];
    cuenta_id?: Maybe<Scalars['String']['output']>;
    estado: EstadoWebhook;
    id: Scalars['String']['output'];
    resultado_mensaje?: Maybe<Scalars['String']['output']>;
    resultado_ok?: Maybe<Scalars['Boolean']['output']>;
    type: Scalars['String']['output'];
    updated_at: Scalars['DateTime']['output'];
};

export type WebhookQueryParams = {
    after?: InputMaybe<Scalars['String']['input']>;
    cuenta_id?: InputMaybe<Scalars['String']['input']>;
    estados?: InputMaybe<Array<EstadoWebhook>>;
    firsts?: InputMaybe<Scalars['Int']['input']>;
    ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ZenRiseApiLog = {
    __typename?: 'ZenRiseApiLog';
    created_at: Scalars['DateTime']['output'];
    id: Scalars['String']['output'];
    method: Scalars['String']['output'];
    requestBody?: Maybe<Scalars['JSONObject']['output']>;
    requestHeaders: Scalars['JSONObject']['output'];
    responseBody?: Maybe<Scalars['JSONObject']['output']>;
    responseHeaders: Scalars['JSONObject']['output'];
    responseStatus: Scalars['Int']['output'];
    updated_at: Scalars['DateTime']['output'];
    url: Scalars['String']['output'];
};

export type TestImportarCuentasMutationVariables = Exact<{
    [key: string]: never;
}>;

export type TestImportarCuentasMutation = {
    __typename?: 'Mutation';
    testImportarCuentas?: boolean | null;
};

export type TestImportarPagosMutationVariables = Exact<{
    [key: string]: never;
}>;

export type TestImportarPagosMutation = {
    __typename?: 'Mutation';
    testImportarPagos?: boolean | null;
};

export type AnalizarMigracionMutationVariables = Exact<{
    [key: string]: never;
}>;

export type AnalizarMigracionMutation = {
    __typename?: 'Mutation';
    analizarImportacion: any;
};

export type GetEstadoCuentasEnSuscripcionesQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetEstadoCuentasEnSuscripcionesQuery = {
    __typename?: 'Query';
    estadoCuentasEnSuscripciones?: {
        __typename?: 'EstadoCuentasEnSubs';
        total?: number | null;
        bloqueadas?: number | null;
        no_bloqueadas?: number | null;
        suscripcion_activa?: number | null;
        trial?: number | null;
        en_migracion?: number | null;
    } | null;
};

export type GetEstadoCuentasEnLegacyQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetEstadoCuentasEnLegacyQuery = {
    __typename?: 'Query';
    estadoCuentasEnLegacy?: {
        __typename?: 'EstadoCuentasEnLegacy';
        total?: number | null;
        bloqueadas?: number | null;
        no_bloqueadas?: number | null;
        sin_auth?: number | null;
    } | null;
};

export type GetEstadoCuentasEnAuthQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetEstadoCuentasEnAuthQuery = {
    __typename?: 'Query';
    estadoCuentasEnAuth?: string | null;
};

export type GetProblemasQueryVariables = Exact<{ [key: string]: never }>;

export type GetProblemasQuery = {
    __typename?: 'Query';
    problemas: { __typename?: 'Problemas'; tiendasSinEmailDesdeLegacy: number };
};

export type CuentaNombreFragment = {
    __typename?: 'Cuenta';
    id: string;
    nombre?: string | null;
} & { ' $fragmentName'?: 'CuentaNombreFragment' };

export type GetEstadisticasAdminQueryVariables = Exact<{
    dias?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetEstadisticasAdminQuery = {
    __typename?: 'Query';
    estadisticasAdmin?: string | null;
};

export type GetCuentasEnMigracionParaAvisarQueryVariables = Exact<{
    fecha: Scalars['String']['input'];
}>;

export type GetCuentasEnMigracionParaAvisarQuery = {
    __typename?: 'Query';
    cuentasEnMigracionParaAvisar?: Array<{
        __typename?: 'Cuenta';
        id: string;
        nombre?: string | null;
        etiquetas?: Array<string> | null;
        suscripcionActiva?: {
            __typename?: 'Suscripcion';
            id: string;
            fecha_proximo_pago?: string | null;
        } | null;
    } | null> | null;
};

export type ProcesoTerminarMigracionQueryVariables = Exact<{
    [key: string]: never;
}>;

export type ProcesoTerminarMigracionQuery = {
    __typename?: 'Query';
    procesoTerminarMigracion?: Array<{
        __typename?: 'RegistroProcesoTerminarMigracion';
        id: string;
        erroed?: string | null;
        cuenta_id: string;
        usuario_tiene_al_comenzar?: boolean | null;
        usuario_temporal_email?: string | null;
        usuario_temporal_password?: string | null;
        fecha_migracion_cancelada?: string | null;
        email_enviado_sendgrid_id?: string | null;
        cuenta?: {
            __typename?: 'Cuenta';
            id: string;
            nombre?: string | null;
        } | null;
    } | null> | null;
};

export type GetDatosAccesoQueryVariables = Exact<{
    stUserId: Scalars['String']['input'];
}>;

export type GetDatosAccesoQuery = {
    __typename?: 'Query';
    usuario?: {
        __typename?: 'Usuario';
        id: string;
        email?: string | null;
        stUserId: string;
        password_temporal?: string | null;
        password_temporal_pendiente_resetear?: boolean | null;
    } | null;
};

export type CambiarClaveMutationVariables = Exact<{
    old_password: Scalars['String']['input'];
    new_password: Scalars['String']['input'];
}>;

export type CambiarClaveMutation = {
    __typename?: 'Mutation';
    cambiarClave?: boolean | null;
};

export type CambiarEmailMutationVariables = Exact<{
    new_email: Scalars['String']['input'];
}>;

export type CambiarEmailMutation = {
    __typename?: 'Mutation';
    cambiarEmail?: boolean | null;
};

export type GetAuditLogsQueryVariables = Exact<{
    params: GenericInputParams;
}>;

export type GetAuditLogsQuery = {
    __typename?: 'Query';
    auditLogs: Array<{
        __typename?: 'AuditLog';
        id: string;
        cuenta_id?: string | null;
        key: string;
        comments?: string | null;
        data?: any | null;
        user_id?: string | null;
        es_admin: boolean;
        es_auto: boolean;
        created_at?: any | null;
        usuario?: {
            __typename?: 'Usuario';
            id: string;
            email?: string | null;
            isSuperAdmin?: boolean | null;
        } | null;
    }>;
};

export type GetAuditLogsPorCuentaQueryVariables = Exact<{
    cuenta_id: Scalars['String']['input'];
}>;

export type GetAuditLogsPorCuentaQuery = {
    __typename?: 'Query';
    cuenta?: {
        __typename?: 'Cuenta';
        id: string;
        auditLogs: Array<{
            __typename?: 'AuditLog';
            id: string;
            key: string;
            comments?: string | null;
            data?: any | null;
            user_id?: string | null;
            es_admin: boolean;
            es_auto: boolean;
            created_at?: any | null;
            usuario?: {
                __typename?: 'Usuario';
                id: string;
                email?: string | null;
                isSuperAdmin?: boolean | null;
            } | null;
        }>;
    } | null;
};

export type GetContratacionesAdminQueryVariables = Exact<{
    params: ContratacionesQueryParams;
}>;

export type GetContratacionesAdminQuery = {
    __typename?: 'Query';
    contrataciones: Array<{
        __typename?: 'Contratacion';
        id: string;
        estado?: EstadoContratacionZenRise | null;
        plan_id?: string | null;
        updated_at?: any | null;
        plan?: {
            __typename?: 'Plan';
            id: string;
            nombre_publico: string;
            nombre_privado: string;
            precio_con_iva: number;
            zenrise_id?: string | null;
        } | null;
        cuenta?: {
            __typename?: 'Cuenta';
            id: string;
            nombre?: string | null;
            legacy_id?: string | null;
            email_desde_legacy?: string | null;
            tn_store_id?: string | null;
            tienda_url?: string | null;
            trial?: {
                __typename?: 'Trial';
                id: string;
                estado: EstadoTrial;
                fecha_fin: string;
            } | null;
        } | null;
    }>;
};

export type GetContratacionActivaQueryVariables = Exact<{
    cuenta_id: Scalars['String']['input'];
}>;

export type GetContratacionActivaQuery = {
    __typename?: 'Query';
    cuenta?: {
        __typename?: 'Cuenta';
        id: string;
        contratacionActiva?: {
            __typename?: 'Contratacion';
            id: string;
            estado?: EstadoContratacionZenRise | null;
            plan?: {
                __typename?: 'Plan';
                id: string;
                precio_con_iva: number;
            } | null;
        } | null;
    } | null;
};

export type GetCuentasQueryVariables = Exact<{ [key: string]: never }>;

export type GetCuentasQuery = {
    __typename?: 'Query';
    cuentas: Array<{
        __typename?: 'Cuenta';
        id: string;
        nombre?: string | null;
        email_pagos?: string | null;
        email_desde_legacy?: string | null;
        tn_store_id?: string | null;
        tienda_url?: string | null;
    }>;
};

export type GetCuentasAdminQueryVariables = Exact<{
    params: CuentasQueryParams;
}>;

export type GetCuentasAdminQuery = {
    __typename?: 'Query';
    cuentas: Array<{
        __typename?: 'Cuenta';
        id: string;
        nombre?: string | null;
        pais?: string | null;
        email_pagos?: string | null;
        email_desde_legacy?: string | null;
        legacy_id?: string | null;
        tipo_doc?: string | null;
        documento?: string | null;
        razon_social?: string | null;
        condicion_ante_iva?: string | null;
        domicilio?: string | null;
        tn_store_id?: string | null;
        tienda_url?: string | null;
    }>;
};

export type GetCuentasBloqueadasAdminQueryVariables = Exact<{
    params: CuentasQueryParams;
}>;

export type GetCuentasBloqueadasAdminQuery = {
    __typename?: 'Query';
    cuentas: Array<{
        __typename?: 'Cuenta';
        id: string;
        nombre?: string | null;
        tn_store_id?: string | null;
        legacy_id?: string | null;
        bloqueada?: boolean | null;
        bloqueo_fecha?: string | null;
        bloqueo_razon?: BloqueoMotivos | null;
        roles?: Array<{
            __typename?: 'Rol';
            id?: string | null;
            usuario?: {
                __typename?: 'Usuario';
                id: string;
                email?: string | null;
            } | null;
        } | null> | null;
    }>;
};

export type GetCuentaDatosFacturacionQueryVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type GetCuentaDatosFacturacionQuery = {
    __typename?: 'Query';
    cuenta?: {
        __typename?: 'Cuenta';
        id: string;
        tipo_doc?: string | null;
        documento?: string | null;
        razon_social?: string | null;
        condicion_ante_iva?: string | null;
        domicilio?: string | null;
    } | null;
};

export type GetDashboardAdminQueryVariables = Exact<{ [key: string]: never }>;

export type GetDashboardAdminQuery = {
    __typename?: 'Query';
    dashboardSuscripciones?: {
        __typename?: 'DashboardSuscripciones';
        mensual?: string | null;
        objetivo?: string | null;
        Porcentaje?: string | null;
        falta?: string | null;
        ClientesActivos?: string | null;
        TicketPromedio?: string | null;
        EnPlanes100?: string | null;
    } | null;
    estadoCuentasEnSuscripciones?: {
        __typename?: 'EstadoCuentasEnSubs';
        total?: number | null;
        bloqueadas?: number | null;
        no_bloqueadas?: number | null;
        suscripcion_activa?: number | null;
        trial?: number | null;
        en_migracion?: number | null;
    } | null;
};

export type GetDatosDesdeTnQueryVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type GetDatosDesdeTnQuery = {
    __typename?: 'Query';
    cuentaMerge?: {
        __typename?: 'CuentaEnLegacy';
        id?: string | null;
        datos_desde_tn?: string | null;
        datos_desde_tn_ultimo_update?: string | null;
    } | null;
};

export type GetCustomerEstadisticasSemanaYMesQueryVariables = Exact<{
    cid: Scalars['String']['input'];
    periodo: PeriodosEstadistica;
}>;

export type GetCustomerEstadisticasSemanaYMesQuery = {
    __typename?: 'Query';
    cuenta?: {
        __typename?: 'CuentaEnLegacy';
        id?: string | null;
        eMes?:
            | ({ __typename?: 'Estadistica' } & {
                  ' $fragmentRefs'?: {
                      EstadisticaDataFragment: EstadisticaDataFragment;
                  };
              })
            | null;
    } | null;
};

export type EstadisticaDataFragment = {
    __typename?: 'Estadistica';
    periodo?: string | null;
    dias_para_atras?: string | null;
    desde?: string | null;
    visitantes?: string | null;
    visitantes_que_interactuaron?: string | null;
    ratio_interaccion?: string | null;
    impresiones?: string | null;
    hovers?: string | null;
    clicks?: string | null;
    ctr?: string | null;
    datos?: Array<{
        __typename?: 'EstadisticaDatos';
        fecha?: string | null;
        impresiones?: number | null;
        hovers?: number | null;
        clicks?: number | null;
    } | null> | null;
} & { ' $fragmentName'?: 'EstadisticaDataFragment' };

export type GetInfoCuentaQueryVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type GetInfoCuentaQuery = {
    __typename?: 'Query';
    cuenta?: {
        __typename?: 'Cuenta';
        id: string;
        nombre?: string | null;
        email_pagos?: string | null;
        bloqueada?: boolean | null;
        bloqueo_fecha?: string | null;
        bloqueo_razon?: BloqueoMotivos | null;
        email_desde_legacy?: string | null;
        tn_store_id?: string | null;
        tienda_url?: string | null;
        legacy_id?: string | null;
        tipo_doc?: string | null;
        documento?: string | null;
        razon_social?: string | null;
        condicion_ante_iva?: string | null;
        domicilio?: string | null;
        etiquetas?: Array<string> | null;
        migracion?: {
            __typename?: 'Migracion';
            id: string;
            analisis_resultado?: boolean | null;
            analisis_problema?: string | null;
            analisis_ult_pago?: string | null;
            analisis_ult_pago_dias?: number | null;
            analisis_prox_pago?: string | null;
            estado?: EstadoMigracion | null;
            legacy_estado?: string | null;
            legacy_estado_tn?: string | null;
            legacy_plan?: string | null;
            json_importacion?: any | null;
        } | null;
        contratacionActiva?: {
            __typename?: 'Contratacion';
            id: string;
            cuenta_id: string;
            estado?: EstadoContratacionZenRise | null;
            zenrise_id?: string | null;
            plan_id?: string | null;
            plan?: {
                __typename?: 'Plan';
                id: string;
                nombre_publico: string;
                nombre_privado: string;
                precio: number;
                precio_con_iva: number;
                zenrise_id?: string | null;
            } | null;
        } | null;
        suscripcionActiva?: {
            __typename?: 'Suscripcion';
            id: string;
            estado: EstadoSuscripcion;
            source_active?: boolean | null;
            source_link?: string | null;
            pasarela_id?: string | null;
            fecha_proximo_pago?: string | null;
            plan?: {
                __typename?: 'Plan';
                id: string;
                precio: number;
                precio_con_iva: number;
                nombre_publico: string;
                nombre_privado: string;
                limite?: number | null;
                mostrar_en_selector?: boolean | null;
                valido_desde?: string | null;
                valido_hasta?: string | null;
            } | null;
            uso?: {
                __typename?: 'Uso';
                id?: string | null;
                dias?: number | null;
                proyectado?: number | null;
                notificaciones?: number | null;
            } | null;
        } | null;
        trial?: {
            __typename?: 'Trial';
            id: string;
            fecha_creacion: string;
            fecha_fin: string;
            estado: EstadoTrial;
        } | null;
    } | null;
};

export type GetInfoParaPantallaFacturacionQueryVariables = Exact<{
    cuenta_id: Scalars['String']['input'];
}>;

export type GetInfoParaPantallaFacturacionQuery = {
    __typename?: 'Query';
    cuenta?: {
        __typename?: 'Cuenta';
        id: string;
        nombre?: string | null;
        email_pagos?: string | null;
        bloqueada?: boolean | null;
        bloqueo_fecha?: string | null;
        bloqueo_razon?: BloqueoMotivos | null;
        email_desde_legacy?: string | null;
        tn_store_id?: string | null;
        tienda_url?: string | null;
        legacy_id?: string | null;
        tipo_doc?: string | null;
        documento?: string | null;
        razon_social?: string | null;
        condicion_ante_iva?: string | null;
        domicilio?: string | null;
        migracion?: {
            __typename?: 'Migracion';
            id: string;
            analisis_resultado?: boolean | null;
            analisis_problema?: string | null;
            analisis_ult_pago?: string | null;
            analisis_ult_pago_dias?: number | null;
            analisis_prox_pago?: string | null;
            estado?: EstadoMigracion | null;
            legacy_estado?: string | null;
            legacy_estado_tn?: string | null;
            legacy_plan?: string | null;
            json_importacion?: any | null;
        } | null;
        contratacionActiva?: {
            __typename?: 'Contratacion';
            id: string;
            cuenta_id: string;
            estado?: EstadoContratacionZenRise | null;
            zenrise_id?: string | null;
            plan_id?: string | null;
            plan?: {
                __typename?: 'Plan';
                id: string;
                nombre_publico: string;
                nombre_privado: string;
                precio: number;
                precio_con_iva: number;
                zenrise_id?: string | null;
            } | null;
        } | null;
        uso?: {
            __typename?: 'Uso';
            id?: string | null;
            notificaciones?: number | null;
            proyectado?: number | null;
            dias?: number | null;
        } | null;
        suscripcionActiva?: {
            __typename?: 'Suscripcion';
            id: string;
            estado: EstadoSuscripcion;
            source_active?: boolean | null;
            source_link?: string | null;
            pasarela_id?: string | null;
            fecha_proximo_pago?: string | null;
            plan?: {
                __typename?: 'Plan';
                id: string;
                nombre_publico: string;
                precio: number;
                precio_con_iva: number;
                limite?: number | null;
            } | null;
            uso?: {
                __typename?: 'Uso';
                id?: string | null;
                notificaciones?: number | null;
                proyectado?: number | null;
                dias?: number | null;
            } | null;
        } | null;
        trial?: {
            __typename?: 'Trial';
            id: string;
            fecha_creacion: string;
            fecha_fin: string;
            estado: EstadoTrial;
        } | null;
    } | null;
    planPorDefecto?: {
        __typename?: 'Plan';
        id: string;
        nombre_publico: string;
        precio_con_iva: number;
        precio: number;
    } | null;
    getPlanesParaCuenta?: Array<{
        __typename?: 'Plan';
        id: string;
        nombre_publico: string;
        nombre_privado: string;
        precio: number;
        precio_con_iva: number;
        por_defecto?: boolean | null;
        zenrise_id?: string | null;
        limite?: number | null;
        valido_desde?: string | null;
        valido_hasta?: string | null;
        mostrar_en_selector?: boolean | null;
    } | null> | null;
};

export type GetInvoicesAdminQueryVariables = Exact<{ [key: string]: never }>;

export type GetInvoicesAdminQuery = {
    __typename?: 'Query';
    invoicesZenRise?: Array<{
        __typename?: 'InvoiceZenRise';
        id: string;
        zenrise_id?: string | null;
        cuenta_id?: string | null;
        fecha_emision?: string | null;
        fecha_pago?: string | null;
        estado_zenrise?: string | null;
        monto?: number | null;
        metodo_pago?: string | null;
        fecha_estimado_acreditacion?: string | null;
        facturada_manual?: boolean | null;
        numero_factura_propia?: string | null;
        facturada_manual_ruta?: string | null;
        created_at?: string | null;
        updated_at?: string | null;
        cuenta?: {
            __typename?: 'Cuenta';
            id: string;
            nombre?: string | null;
            tipo_doc?: string | null;
            documento?: string | null;
            condicion_ante_iva?: string | null;
            razon_social?: string | null;
            domicilio?: string | null;
        } | null;
    } | null> | null;
};

export type GetInvoicesPorCuentaQueryVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type GetInvoicesPorCuentaQuery = {
    __typename?: 'Query';
    cuenta?: {
        __typename?: 'Cuenta';
        id: string;
        nombre?: string | null;
        tipo_doc?: string | null;
        documento?: string | null;
        condicion_ante_iva?: string | null;
        razon_social?: string | null;
        domicilio?: string | null;
        invoices?: Array<{
            __typename?: 'InvoiceZenRise';
            id: string;
            zenrise_id?: string | null;
            fecha_emision?: string | null;
            fecha_pago?: string | null;
            estado_zenrise?: string | null;
            monto?: number | null;
            metodo_pago?: string | null;
            fecha_estimado_acreditacion?: string | null;
            facturada_manual?: boolean | null;
            numero_factura_propia?: string | null;
            facturada_manual_ruta?: string | null;
            created_at?: string | null;
            updated_at?: string | null;
        } | null> | null;
    } | null;
};

export type GetIpNsQueryVariables = Exact<{
    params: IpnParams;
}>;

export type GetIpNsQuery = {
    __typename?: 'Query';
    ipns: Array<{
        __typename?: 'IPN';
        id: string;
        body: string;
        estado: EstadoIpn;
        externalReference: string;
        invoiceId: number;
        resultado_mensaje?: string | null;
        resultado_ok?: boolean | null;
        transactionId: string;
        created_at: any;
        updated_at: any;
    }>;
};

export type GetLogsQueryVariables = Exact<{ [key: string]: never }>;

export type GetLogsQuery = {
    __typename?: 'Query';
    zenrise_logs: Array<{
        __typename?: 'ZenRiseApiLog';
        id: string;
        url: string;
        created_at: any;
        requestBody?: any | null;
        method: string;
        requestHeaders: any;
        responseStatus: number;
        responseBody?: any | null;
        responseHeaders: any;
    }>;
};

export type GetMigracionesAdminQueryVariables = Exact<{
    params: MigracionesQueryParams;
}>;

export type GetMigracionesAdminQuery = {
    __typename?: 'Query';
    migraciones: Array<{
        __typename?: 'Migracion';
        id: string;
        estado?: EstadoMigracion | null;
        suscripcion_id?: string | null;
        tn_store_id?: string | null;
        legacy_id?: string | null;
        creada_en_legacy?: string | null;
        cuenta_id: string;
        analisis_fecha?: string | null;
        analisis_resultado?: boolean | null;
        analisis_problema?: string | null;
        analisis_comentario?: string | null;
        analisis_ult_pago?: string | null;
        analisis_prox_pago?: string | null;
        analisis_ult_pago_dias?: number | null;
        legacy_estado?: string | null;
        legacy_estado_tn?: string | null;
        legacy_plan?: string | null;
        cuenta?: {
            __typename?: 'Cuenta';
            id: string;
            nombre?: string | null;
            legacy_id?: string | null;
            email_pagos?: string | null;
            bloqueada?: boolean | null;
            bloqueo_fecha?: string | null;
            etiquetas?: Array<string> | null;
            sparkline?: Array<number | null> | null;
            pais?: string | null;
            trial?: {
                __typename?: 'Trial';
                id: string;
                estado: EstadoTrial;
                fecha_fin: string;
            } | null;
            suscripcionActiva?: {
                __typename?: 'Suscripcion';
                id: string;
                estado: EstadoSuscripcion;
                fecha_proximo_pago?: string | null;
            } | null;
            uso?: {
                __typename?: 'Uso';
                id?: string | null;
                notificaciones?: number | null;
                proyectado?: number | null;
                dias?: number | null;
            } | null;
            roles?: Array<{
                __typename?: 'Rol';
                id?: string | null;
                rolNombre?: string | null;
                usuario?: {
                    __typename?: 'Usuario';
                    id: string;
                    email?: string | null;
                } | null;
            } | null> | null;
        } | null;
    }>;
};

export type GetPagosTnPorSuscripcionQueryVariables = Exact<{
    cuenta_id: Scalars['String']['input'];
}>;

export type GetPagosTnPorSuscripcionQuery = {
    __typename?: 'Query';
    cuenta?: {
        __typename?: 'Cuenta';
        id: string;
        tn_store_id?: string | null;
        pagos?: Array<{
            __typename?: 'PagoTN';
            import_id: string;
            monto: number;
            dias: number;
            fecha_pago: string;
            moneda: string;
            tn_store_id: string;
        }> | null;
    } | null;
};

export type GetPlanesAdminQueryVariables = Exact<{ [key: string]: never }>;

export type GetPlanesAdminQuery = {
    __typename?: 'Query';
    planes: Array<{
        __typename?: 'Plan';
        id: string;
        nombre_publico: string;
        nombre_privado: string;
        precio: number;
        precio_con_iva: number;
        por_defecto?: boolean | null;
        zenrise_id?: string | null;
        limite?: number | null;
        valido_desde?: string | null;
        valido_hasta?: string | null;
        mostrar_en_selector?: boolean | null;
    }>;
};

export type GetPlanDetallesQueryVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type GetPlanDetallesQuery = {
    __typename?: 'Query';
    plan?: {
        __typename?: 'Plan';
        id: string;
        nombre_publico: string;
        nombre_privado: string;
        precio: number;
        precio_con_iva: number;
        por_defecto?: boolean | null;
        zenrise_id?: string | null;
        limite?: number | null;
        valido_desde?: string | null;
        valido_hasta?: string | null;
        mostrar_en_selector?: boolean | null;
    } | null;
};

export type GetReporteCuentasActivasConPagosAtrasadosQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetReporteCuentasActivasConPagosAtrasadosQuery = {
    __typename?: 'Query';
    reporteCuentasActivasConPagosAtrasados?: string | null;
};

export type GetSuscripcionesAdminQueryVariables = Exact<{
    params: SuscripcionesQueryParams;
}>;

export type GetSuscripcionesAdminQuery = {
    __typename?: 'Query';
    suscripciones: Array<{
        __typename?: 'Suscripcion';
        id: string;
        estado: EstadoSuscripcion;
        fecha_ultimo_pago?: string | null;
        fecha_proximo_pago?: string | null;
        fecha_creacion: string;
        source_active?: boolean | null;
        plan?: {
            __typename?: 'Plan';
            id: string;
            nombre_publico: string;
            nombre_privado: string;
            precio: number;
            limite?: number | null;
        } | null;
        uso?: {
            __typename?: 'Uso';
            id?: string | null;
            notificaciones?: number | null;
            proyectado?: number | null;
            dias?: number | null;
        } | null;
        cuenta?: {
            __typename?: 'Cuenta';
            id: string;
            nombre?: string | null;
            legacy_id?: string | null;
            tn_store_id?: string | null;
            condicion_ante_iva?: string | null;
            tipo_doc?: string | null;
            documento?: string | null;
            pais?: string | null;
            sparkline?: Array<number | null> | null;
            uso?: {
                __typename?: 'Uso';
                id?: string | null;
                notificaciones?: number | null;
                proyectado?: number | null;
                dias?: number | null;
            } | null;
        } | null;
    }>;
};

export type GetSuscripcionesParaBloquearAdminQueryVariables = Exact<{
    fecha: Scalars['String']['input'];
}>;

export type GetSuscripcionesParaBloquearAdminQuery = {
    __typename?: 'Query';
    suscripcionesParaBloquear?: Array<{
        __typename?: 'Suscripcion';
        id: string;
        estado: EstadoSuscripcion;
        fecha_proximo_pago?: string | null;
        cuenta?: {
            __typename?: 'Cuenta';
            id: string;
            nombre?: string | null;
            email_pagos?: string | null;
            legacy_id?: string | null;
            tn_store_id?: string | null;
            trial?: {
                __typename?: 'Trial';
                id: string;
                estado: EstadoTrial;
                fecha_fin: string;
            } | null;
        } | null;
    } | null> | null;
};

export type GetTrialsAdminQueryVariables = Exact<{
    params: TrialsQueryParams;
}>;

export type GetTrialsAdminQuery = {
    __typename?: 'Query';
    trials: Array<{
        __typename?: 'Trial';
        id: string;
        estado: EstadoTrial;
        fecha_creacion: string;
        fecha_fin: string;
        cuenta?: {
            __typename?: 'Cuenta';
            id: string;
            nombre?: string | null;
            email_desde_legacy?: string | null;
            tn_store_id?: string | null;
            tienda_url?: string | null;
            contratacionActiva?: {
                __typename?: 'Contratacion';
                id: string;
                estado?: EstadoContratacionZenRise | null;
            } | null;
            uso?: {
                __typename?: 'Uso';
                id?: string | null;
                notificaciones?: number | null;
                proyectado?: number | null;
                dias?: number | null;
            } | null;
        } | null;
    }>;
};

export type GetTrialsParaTerminarQueryVariables = Exact<{
    fecha: Scalars['String']['input'];
}>;

export type GetTrialsParaTerminarQuery = {
    __typename?: 'Query';
    trialsParaTerminar?: Array<{
        __typename?: 'Trial';
        id: string;
        estado: EstadoTrial;
        fecha_fin: string;
        cuenta?: {
            __typename?: 'Cuenta';
            id: string;
            nombre?: string | null;
            legacy_id?: string | null;
            tn_store_id?: string | null;
            tienda_url?: string | null;
            contratacionActiva?: {
                __typename?: 'Contratacion';
                id: string;
                estado?: EstadoContratacionZenRise | null;
            } | null;
            suscripcionActiva?: {
                __typename?: 'Suscripcion';
                id: string;
                estado: EstadoSuscripcion;
            } | null;
        } | null;
    } | null> | null;
};

export type GetUsuariosAdminQueryVariables = Exact<{
    params: UsuariosInputParams;
}>;

export type GetUsuariosAdminQuery = {
    __typename?: 'Query';
    usuarios?: Array<{
        __typename?: 'Usuario';
        id: string;
        email?: string | null;
        uid?: string | null;
        cid?: string | null;
        isSuperAdmin?: boolean | null;
        stUserId: string;
        updatedAt?: string | null;
        createdAt?: string | null;
        fecha_creacion_manual?: string | null;
        password_temporal?: string | null;
        password_temporal_pendiente_resetear?: boolean | null;
    } | null> | null;
};

export type GetUsuariosPorCuentaAdminQueryVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type GetUsuariosPorCuentaAdminQuery = {
    __typename?: 'Query';
    cuenta?: {
        __typename?: 'Cuenta';
        id: string;
        legacy_id?: string | null;
        roles?: Array<{
            __typename?: 'Rol';
            id?: string | null;
            rolNombre?: string | null;
            usuario?: {
                __typename?: 'Usuario';
                id: string;
                email?: string | null;
                stUserId: string;
                uid?: string | null;
                createdAt?: string | null;
                updatedAt?: string | null;
                fecha_creacion_manual?: string | null;
                password_temporal?: string | null;
                password_temporal_pendiente_resetear?: boolean | null;
            } | null;
        } | null> | null;
    } | null;
    cuentaMerge?: {
        __typename?: 'CuentaEnLegacy';
        id?: string | null;
        user_id?: string | null;
    } | null;
};

export type GetWebhooksQueryVariables = Exact<{
    params: WebhookQueryParams;
}>;

export type GetWebhooksQuery = {
    __typename?: 'Query';
    webhooks: Array<{
        __typename?: 'Webhook';
        id: string;
        cuenta_id?: string | null;
        type: string;
        body: any;
        estado: EstadoWebhook;
        resultado_ok?: boolean | null;
        resultado_mensaje?: string | null;
        created_at: any;
        updated_at: any;
    }>;
};

export type BloquearCuentaMutationVariables = Exact<{
    cuenta_id: Scalars['String']['input'];
}>;

export type BloquearCuentaMutation = {
    __typename?: 'Mutation';
    testBloquearCuenta?: boolean | null;
};

export type CambiarDatosFacturacionMutationVariables = Exact<{
    params: CambiarDatosFacturacionParams;
}>;

export type CambiarDatosFacturacionMutation = {
    __typename?: 'Mutation';
    cambiarDatosFacturacion?: {
        __typename?: 'Cuenta';
        id: string;
        tipo_doc?: string | null;
        documento?: string | null;
        razon_social?: string | null;
        condicion_ante_iva?: string | null;
        domicilio?: string | null;
    } | null;
};

export type CancelarCuentaMutationVariables = Exact<{
    params: InputCancelarParams;
}>;

export type CancelarCuentaMutation = {
    __typename?: 'Mutation';
    cancelar: boolean;
};

export type ContratarZenriseMutationVariables = Exact<{
    nombre: Scalars['String']['input'];
    dni: Scalars['String']['input'];
    cuenta_id: Scalars['String']['input'];
    token: Scalars['JSON']['input'];
    es_tarjeta_credito: Scalars['Boolean']['input'];
    plan_id: Scalars['String']['input'];
}>;

export type ContratarZenriseMutation = {
    __typename?: 'Mutation';
    contratarZenrise: {
        __typename?: 'ContratarZenriseResponse';
        ok: boolean;
        mensaje_error?: string | null;
    };
};

export type CrearUsuarioManualMutationVariables = Exact<{
    params: CrearUsuarioManualmenteParams;
}>;

export type CrearUsuarioManualMutation = {
    __typename?: 'Mutation';
    crearUsuarioManualmente?: { __typename?: 'Usuario'; id: string } | null;
};

export type DesbloquearCuentaMutationVariables = Exact<{
    cuenta_id: Scalars['String']['input'];
}>;

export type DesbloquearCuentaMutation = {
    __typename?: 'Mutation';
    testDesbloquearCuenta?: boolean | null;
};

export type ElegirPlanMutationVariables = Exact<{
    cuenta_id: Scalars['String']['input'];
    plan_id: Scalars['String']['input'];
}>;

export type ElegirPlanMutation = {
    __typename?: 'Mutation';
    elegirPlan?: boolean | null;
};

export type FixDatosDesdeLegacyMutationVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type FixDatosDesdeLegacyMutation = {
    __typename?: 'Mutation';
    fixDatosDesdeLegacy?: boolean | null;
};

export type IniciarContratacionZenriseMutationVariables = Exact<{
    cuenta_id: Scalars['String']['input'];
    contratacion_id: Scalars['String']['input'];
}>;

export type IniciarContratacionZenriseMutation = {
    __typename?: 'Mutation';
    iniciarContratacionZenrise: string;
};

export type MandarPlanZenRiseMutationVariables = Exact<{
    plan_id: Scalars['String']['input'];
}>;

export type MandarPlanZenRiseMutation = {
    __typename?: 'Mutation';
    mandarPlanZenRise: string;
};

export type MigracionCancelarMutationVariables = Exact<{
    cuenta_id: Scalars['String']['input'];
    comentario: Scalars['String']['input'];
}>;

export type MigracionCancelarMutation = {
    __typename?: 'Mutation';
    migracionCancelar: boolean;
};

export type MigracionMarcarComoListaMutationVariables = Exact<{
    cuenta_id: Scalars['String']['input'];
    fecha_proximo_pago: Scalars['Date']['input'];
}>;

export type MigracionMarcarComoListaMutation = {
    __typename?: 'Mutation';
    migracionMarcarComoLista: boolean;
};

export type MigracionPasarGestionManualMutationVariables = Exact<{
    cuenta_id: Scalars['String']['input'];
}>;

export type MigracionPasarGestionManualMutation = {
    __typename?: 'Mutation';
    migracionPasarGestionManual: boolean;
};

export type ProcesarIpnMutationVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type ProcesarIpnMutation = {
    __typename?: 'Mutation';
    procesarIPN: {
        __typename?: 'ProcesarIPNResultado';
        ok: boolean;
        mensaje?: string | null;
    };
};

export type TestProcesarWebhookMutationVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type TestProcesarWebhookMutation = {
    __typename?: 'Mutation';
    rst: {
        __typename?: 'ProcesarWebhookResultado';
        ok: boolean;
        mensaje?: string | null;
    };
};

export type RecibirIpnMutationVariables = Exact<{
    content: Scalars['String']['input'];
}>;

export type RecibirIpnMutation = {
    __typename?: 'Mutation';
    testSimularIPNRecibido: boolean;
};

export type RecibirWebhookMutationVariables = Exact<{
    content: Scalars['String']['input'];
}>;

export type RecibirWebhookMutation = {
    __typename?: 'Mutation';
    testSimularWebhookRecibido: boolean;
};

export type ResetDbMutationVariables = Exact<{ [key: string]: never }>;

export type ResetDbMutation = { __typename?: 'Mutation'; resetDb: boolean };

export type UpdateDatosTnMutationVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type UpdateDatosTnMutation = {
    __typename?: 'Mutation';
    UpdateInfoDesdeTn?: boolean | null;
};

export type TestCrearPlanInicialMutationVariables = Exact<{
    [key: string]: never;
}>;

export type TestCrearPlanInicialMutation = {
    __typename?: 'Mutation';
    crearPlanInicial: string;
};

export type TestCrearTrialMutationVariables = Exact<{
    dias: Scalars['Int']['input'];
}>;

export type TestCrearTrialMutation = {
    __typename?: 'Mutation';
    testCrearCuentaEnTrial: string;
};

export const CuentaNombreFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'cuentaNombre' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Cuenta' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'nombre' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CuentaNombreFragment, unknown>;
export const EstadisticaDataFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'estadisticaData' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Estadistica' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'periodo' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dias_para_atras' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'desde' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'visitantes' },
                    },
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'visitantes_que_interactuaron',
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ratio_interaccion' },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'impresiones' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'hovers' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'clicks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ctr' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'datos' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fecha' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'impresiones',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hovers' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'clicks' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<EstadisticaDataFragment, unknown>;
export const TestImportarCuentasDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'testImportarCuentas' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'testImportarCuentas' },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    TestImportarCuentasMutation,
    TestImportarCuentasMutationVariables
>;
export const TestImportarPagosDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'testImportarPagos' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'testImportarPagos' },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    TestImportarPagosMutation,
    TestImportarPagosMutationVariables
>;
export const AnalizarMigracionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'analizarMigracion' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'analizarImportacion' },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AnalizarMigracionMutation,
    AnalizarMigracionMutationVariables
>;
export const GetEstadoCuentasEnSuscripcionesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getEstadoCuentasEnSuscripciones' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'estadoCuentasEnSuscripciones',
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'total' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bloqueadas' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'no_bloqueadas',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'suscripcion_activa',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'trial' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'en_migracion',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetEstadoCuentasEnSuscripcionesQuery,
    GetEstadoCuentasEnSuscripcionesQueryVariables
>;
export const GetEstadoCuentasEnLegacyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getEstadoCuentasEnLegacy' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estadoCuentasEnLegacy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'total' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bloqueadas' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'no_bloqueadas',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sin_auth' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetEstadoCuentasEnLegacyQuery,
    GetEstadoCuentasEnLegacyQueryVariables
>;
export const GetEstadoCuentasEnAuthDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getEstadoCuentasEnAuth' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estadoCuentasEnAuth' },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetEstadoCuentasEnAuthQuery,
    GetEstadoCuentasEnAuthQueryVariables
>;
export const GetProblemasDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getProblemas' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'problemas' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'tiendasSinEmailDesdeLegacy',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetProblemasQuery, GetProblemasQueryVariables>;
export const GetEstadisticasAdminDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getEstadisticasAdmin' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dias' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Int' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estadisticasAdmin' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'dias' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'dias' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetEstadisticasAdminQuery,
    GetEstadisticasAdminQueryVariables
>;
export const GetCuentasEnMigracionParaAvisarDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getCuentasEnMigracionParaAvisar' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fecha' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'cuentasEnMigracionParaAvisar',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fecha' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'fecha' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nombre' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'etiquetas' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'suscripcionActiva',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fecha_proximo_pago',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetCuentasEnMigracionParaAvisarQuery,
    GetCuentasEnMigracionParaAvisarQueryVariables
>;
export const ProcesoTerminarMigracionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'procesoTerminarMigracion' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'procesoTerminarMigracion',
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'erroed' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cuenta_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cuenta' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'nombre',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'usuario_tiene_al_comenzar',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'usuario_temporal_email',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'usuario_temporal_password',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'fecha_migracion_cancelada',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'email_enviado_sendgrid_id',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ProcesoTerminarMigracionQuery,
    ProcesoTerminarMigracionQueryVariables
>;
export const GetDatosAccesoDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getDatosAcceso' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'stUserId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'usuario' },
                        name: { kind: 'Name', value: 'usuarioByST' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'stUserId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'stUserId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'stUserId' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'password_temporal',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'password_temporal_pendiente_resetear',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetDatosAccesoQuery, GetDatosAccesoQueryVariables>;
export const CambiarClaveDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'cambiarClave' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'old_password' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'new_password' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cambiarClave' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'old_password' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'old_password',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'new_password' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'new_password',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CambiarClaveMutation,
    CambiarClaveMutationVariables
>;
export const CambiarEmailDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'cambiarEmail' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'new_email' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cambiarEmail' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'new_email' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'new_email' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CambiarEmailMutation,
    CambiarEmailMutationVariables
>;
export const GetAuditLogsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getAuditLogs' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'params' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'GenericInputParams' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'auditLogs' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'params' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'params' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cuenta_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'key' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'comments' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'es_admin' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'es_auto' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'created_at' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'usuario' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'email',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'isSuperAdmin',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetAuditLogsQuery, GetAuditLogsQueryVariables>;
export const GetAuditLogsPorCuentaDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getAuditLogsPorCuenta' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'cuenta_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cuenta' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'cuenta_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'auditLogs' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'key',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'comments',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'data',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'user_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'es_admin',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'es_auto',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'created_at',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'usuario',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'email',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'isSuperAdmin',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetAuditLogsPorCuentaQuery,
    GetAuditLogsPorCuentaQueryVariables
>;
export const GetContratacionesAdminDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getContratacionesAdmin' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'params' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'ContratacionesQueryParams',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contrataciones' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'params' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'params' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'estado' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'plan_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updated_at' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'plan' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'nombre_publico',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'nombre_privado',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'precio_con_iva',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'zenrise_id',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cuenta' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'nombre',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'legacy_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'email_desde_legacy',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'tn_store_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'tienda_url',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'trial',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'estado',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fecha_fin',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetContratacionesAdminQuery,
    GetContratacionesAdminQueryVariables
>;
export const GetContratacionActivaDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getContratacionActiva' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'cuenta_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cuenta' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'cuenta_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'contratacionActiva',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'estado',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'plan',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'precio_con_iva',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetContratacionActivaQuery,
    GetContratacionActivaQueryVariables
>;
export const GetCuentasDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getCuentas' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cuentas' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'params' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: {
                                                kind: 'Name',
                                                value: 'firsts',
                                            },
                                            value: {
                                                kind: 'IntValue',
                                                value: '10',
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nombre' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'email_pagos',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'email_desde_legacy',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'tn_store_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'tienda_url' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetCuentasQuery, GetCuentasQueryVariables>;
export const GetCuentasAdminDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getCuentasAdmin' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'params' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CuentasQueryParams' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cuentas' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'params' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'params' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nombre' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pais' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'email_pagos',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'email_desde_legacy',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'legacy_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'tipo_doc' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documento' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'razon_social',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'condicion_ante_iva',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'domicilio' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'tn_store_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'tienda_url' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetCuentasAdminQuery,
    GetCuentasAdminQueryVariables
>;
export const GetCuentasBloqueadasAdminDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getCuentasBloqueadasAdmin' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'params' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CuentasQueryParams' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cuentas' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'params' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'params' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nombre' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'tn_store_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'legacy_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bloqueada' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'bloqueo_fecha',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'bloqueo_razon',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'roles' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'usuario',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'email',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetCuentasBloqueadasAdminQuery,
    GetCuentasBloqueadasAdminQueryVariables
>;
export const GetCuentaDatosFacturacionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getCuentaDatosFacturacion' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cuenta' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'tipo_doc' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documento' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'razon_social',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'condicion_ante_iva',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'domicilio' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetCuentaDatosFacturacionQuery,
    GetCuentaDatosFacturacionQueryVariables
>;
export const GetDashboardAdminDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getDashboardAdmin' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dashboardSuscripciones' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mensual' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'objetivo' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Porcentaje' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'falta' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'ClientesActivos',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'TicketPromedio',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'EnPlanes100',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'estadoCuentasEnSuscripciones',
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'total' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bloqueadas' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'no_bloqueadas',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'suscripcion_activa',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'trial' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'en_migracion',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetDashboardAdminQuery,
    GetDashboardAdminQueryVariables
>;
export const GetDatosDesdeTnDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getDatosDesdeTN' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cuentaMerge' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'datos_desde_tn',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'datos_desde_tn_ultimo_update',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetDatosDesdeTnQuery,
    GetDatosDesdeTnQueryVariables
>;
export const GetCustomerEstadisticasSemanaYMesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getCustomerEstadisticasSemanaYMes' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'cid' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'periodo' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'PeriodosEstadistica',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'cuenta' },
                        name: { kind: 'Name', value: 'cuentaMerge' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'cid' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'eMes' },
                                    name: {
                                        kind: 'Name',
                                        value: 'estadisticas',
                                    },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: {
                                                kind: 'Name',
                                                value: 'periodo',
                                            },
                                            value: {
                                                kind: 'Variable',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'periodo',
                                                },
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'estadisticaData',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'estadisticaData' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Estadistica' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'periodo' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dias_para_atras' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'desde' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'visitantes' },
                    },
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'visitantes_que_interactuaron',
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ratio_interaccion' },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'impresiones' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'hovers' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'clicks' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ctr' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'datos' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fecha' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'impresiones',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hovers' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'clicks' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetCustomerEstadisticasSemanaYMesQuery,
    GetCustomerEstadisticasSemanaYMesQueryVariables
>;
export const GetInfoCuentaDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getInfoCuenta' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cuenta' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nombre' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'email_pagos',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bloqueada' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'bloqueo_fecha',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'bloqueo_razon',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'email_desde_legacy',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'tn_store_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'tienda_url' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'legacy_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'tipo_doc' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documento' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'razon_social',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'condicion_ante_iva',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'domicilio' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'etiquetas' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'migracion' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'analisis_resultado',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'analisis_problema',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'analisis_ult_pago',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'analisis_ult_pago_dias',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'analisis_prox_pago',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'estado',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'legacy_estado',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'legacy_estado_tn',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'legacy_plan',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'json_importacion',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'contratacionActiva',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'cuenta_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'estado',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'zenrise_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'plan_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'plan',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'nombre_publico',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'nombre_privado',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'precio',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'precio_con_iva',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'zenrise_id',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'suscripcionActiva',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'estado',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'source_active',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'source_link',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'pasarela_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fecha_proximo_pago',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'plan',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'precio',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'precio_con_iva',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'nombre_publico',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'nombre_privado',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'limite',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'mostrar_en_selector',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'valido_desde',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'valido_hasta',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'uso',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'dias',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'proyectado',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'notificaciones',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'trial' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fecha_creacion',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fecha_fin',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'estado',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetInfoCuentaQuery, GetInfoCuentaQueryVariables>;
export const GetInfoParaPantallaFacturacionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getInfoParaPantallaFacturacion' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'cuenta_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cuenta' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'cuenta_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nombre' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'email_pagos',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bloqueada' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'bloqueo_fecha',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'bloqueo_razon',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'email_desde_legacy',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'tn_store_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'tienda_url' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'legacy_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'tipo_doc' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documento' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'razon_social',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'condicion_ante_iva',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'domicilio' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'migracion' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'analisis_resultado',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'analisis_problema',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'analisis_ult_pago',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'analisis_ult_pago_dias',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'analisis_prox_pago',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'estado',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'legacy_estado',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'legacy_estado_tn',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'legacy_plan',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'json_importacion',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'contratacionActiva',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'cuenta_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'estado',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'zenrise_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'plan_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'plan',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'nombre_publico',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'nombre_privado',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'precio',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'precio_con_iva',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'zenrise_id',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uso' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'notificaciones',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'proyectado',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'dias',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'suscripcionActiva',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'estado',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'source_active',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'source_link',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'pasarela_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fecha_proximo_pago',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'plan',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'nombre_publico',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'precio',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'precio_con_iva',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'limite',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'uso',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'notificaciones',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'proyectado',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'dias',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'trial' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fecha_creacion',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fecha_fin',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'estado',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'planPorDefecto' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'nombre_publico',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'precio_con_iva',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'precio' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getPlanesParaCuenta' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'cuenta_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'cuenta_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'nombre_publico',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'nombre_privado',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'precio' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'precio_con_iva',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'por_defecto',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'zenrise_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'limite' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'valido_desde',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'valido_hasta',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'mostrar_en_selector',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetInfoParaPantallaFacturacionQuery,
    GetInfoParaPantallaFacturacionQueryVariables
>;
export const GetInvoicesAdminDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getInvoicesAdmin' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invoicesZenRise' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'zenrise_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cuenta_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cuenta' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'nombre',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'tipo_doc',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'documento',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'condicion_ante_iva',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'razon_social',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'domicilio',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'fecha_emision',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fecha_pago' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'estado_zenrise',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'monto' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'metodo_pago',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'fecha_estimado_acreditacion',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'facturada_manual',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'numero_factura_propia',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'facturada_manual_ruta',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'created_at' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updated_at' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetInvoicesAdminQuery,
    GetInvoicesAdminQueryVariables
>;
export const GetInvoicesPorCuentaDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getInvoicesPorCuenta' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cuenta' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nombre' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'tipo_doc' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documento' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'condicion_ante_iva',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'razon_social',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'domicilio' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'invoices' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'zenrise_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fecha_emision',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fecha_pago',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'estado_zenrise',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'monto',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'metodo_pago',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fecha_estimado_acreditacion',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'facturada_manual',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'numero_factura_propia',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'facturada_manual_ruta',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'created_at',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'updated_at',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetInvoicesPorCuentaQuery,
    GetInvoicesPorCuentaQueryVariables
>;
export const GetIpNsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getIPNs' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'params' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'IPNParams' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ipns' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'params' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'params' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'body' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'estado' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'externalReference',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'invoiceId' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'resultado_mensaje',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'resultado_ok',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'transactionId',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'created_at' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updated_at' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetIpNsQuery, GetIpNsQueryVariables>;
export const GetLogsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getLogs' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'zenrise_logs' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'created_at' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'requestBody',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'method' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'requestHeaders',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'responseStatus',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'responseBody',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'responseHeaders',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetLogsQuery, GetLogsQueryVariables>;
export const GetMigracionesAdminDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getMigracionesAdmin' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'params' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'MigracionesQueryParams',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'migraciones' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'params' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'params' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'estado' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'suscripcion_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'tn_store_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'legacy_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'creada_en_legacy',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cuenta_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'analisis_fecha',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'analisis_resultado',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'analisis_problema',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'analisis_comentario',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'analisis_ult_pago',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'analisis_prox_pago',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'analisis_ult_pago_dias',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cuenta' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'nombre',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'legacy_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'email_pagos',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'bloqueada',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'bloqueo_fecha',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'etiquetas',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'sparkline',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'pais',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'trial',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'estado',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fecha_fin',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'suscripcionActiva',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'estado',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fecha_proximo_pago',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'uso',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'notificaciones',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'proyectado',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'dias',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'roles',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'rolNombre',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'usuario',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'email',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'legacy_estado',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'legacy_estado_tn',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'legacy_plan',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetMigracionesAdminQuery,
    GetMigracionesAdminQueryVariables
>;
export const GetPagosTnPorSuscripcionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getPagosTnPorSuscripcion' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'cuenta_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cuenta' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'cuenta_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'tn_store_id',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pagos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'import_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'monto',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'dias',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fecha_pago',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'moneda',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'tn_store_id',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetPagosTnPorSuscripcionQuery,
    GetPagosTnPorSuscripcionQueryVariables
>;
export const GetPlanesAdminDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getPlanesAdmin' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'planes' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'nombre_publico',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'nombre_privado',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'precio' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'precio_con_iva',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'por_defecto',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'zenrise_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'limite' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'valido_desde',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'valido_hasta',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'mostrar_en_selector',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetPlanesAdminQuery, GetPlanesAdminQueryVariables>;
export const GetPlanDetallesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getPlanDetalles' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'plan' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'nombre_publico',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'nombre_privado',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'precio' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'precio_con_iva',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'por_defecto',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'zenrise_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'limite' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'valido_desde',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'valido_hasta',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'mostrar_en_selector',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetPlanDetallesQuery,
    GetPlanDetallesQueryVariables
>;
export const GetReporteCuentasActivasConPagosAtrasadosDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: {
                kind: 'Name',
                value: 'getReporteCuentasActivasConPagosAtrasados',
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'reporteCuentasActivasConPagosAtrasados',
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetReporteCuentasActivasConPagosAtrasadosQuery,
    GetReporteCuentasActivasConPagosAtrasadosQueryVariables
>;
export const GetSuscripcionesAdminDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getSuscripcionesAdmin' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'params' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'SuscripcionesQueryParams',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'suscripciones' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'params' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'params' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'estado' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'fecha_ultimo_pago',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'fecha_proximo_pago',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'fecha_creacion',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'source_active',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'plan' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'nombre_publico',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'nombre_privado',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'precio',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'limite',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uso' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'notificaciones',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'proyectado',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'dias',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cuenta' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'nombre',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'legacy_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'tn_store_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'condicion_ante_iva',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'tipo_doc',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'documento',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'pais',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'uso',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'notificaciones',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'proyectado',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'dias',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'sparkline',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetSuscripcionesAdminQuery,
    GetSuscripcionesAdminQueryVariables
>;
export const GetSuscripcionesParaBloquearAdminDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getSuscripcionesParaBloquearAdmin' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fecha' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'suscripcionesParaBloquear',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fecha' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'fecha' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'estado' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'fecha_proximo_pago',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cuenta' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'nombre',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'email_pagos',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'legacy_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'tn_store_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'trial',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'estado',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fecha_fin',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetSuscripcionesParaBloquearAdminQuery,
    GetSuscripcionesParaBloquearAdminQueryVariables
>;
export const GetTrialsAdminDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getTrialsAdmin' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'params' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'TrialsQueryParams' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'trials' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'params' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'params' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'estado' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'fecha_creacion',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fecha_fin' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cuenta' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'nombre',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'email_desde_legacy',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'tn_store_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'tienda_url',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'contratacionActiva',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'estado',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'uso',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'notificaciones',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'proyectado',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'dias',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetTrialsAdminQuery, GetTrialsAdminQueryVariables>;
export const GetTrialsParaTerminarDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getTrialsParaTerminar' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fecha' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'trialsParaTerminar' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fecha' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'fecha' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'estado' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fecha_fin' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cuenta' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'nombre',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'legacy_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'tn_store_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'tienda_url',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'contratacionActiva',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'estado',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'suscripcionActiva',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'estado',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetTrialsParaTerminarQuery,
    GetTrialsParaTerminarQueryVariables
>;
export const GetUsuariosAdminDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getUsuariosAdmin' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'params' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'UsuariosInputParams',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'usuarios' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'params' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'params' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uid' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cid' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'isSuperAdmin',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'stUserId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedAt' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdAt' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'fecha_creacion_manual',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'password_temporal',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'password_temporal_pendiente_resetear',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetUsuariosAdminQuery,
    GetUsuariosAdminQueryVariables
>;
export const GetUsuariosPorCuentaAdminDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getUsuariosPorCuentaAdmin' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cuenta' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'legacy_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'roles' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'rolNombre',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'usuario',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'email',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'stUserId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'uid',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'createdAt',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'updatedAt',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fecha_creacion_manual',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'password_temporal',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'password_temporal_pendiente_resetear',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cuentaMerge' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user_id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetUsuariosPorCuentaAdminQuery,
    GetUsuariosPorCuentaAdminQueryVariables
>;
export const GetWebhooksDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getWebhooks' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'params' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'WebhookQueryParams' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webhooks' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'params' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'params' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cuenta_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'body' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'estado' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'resultado_ok',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'resultado_mensaje',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'created_at' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updated_at' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetWebhooksQuery, GetWebhooksQueryVariables>;
export const BloquearCuentaDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'bloquearCuenta' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'cuenta_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'testBloquearCuenta' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'cuenta_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'cuenta_id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    BloquearCuentaMutation,
    BloquearCuentaMutationVariables
>;
export const CambiarDatosFacturacionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'cambiarDatosFacturacion' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'params' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'CambiarDatosFacturacionParams',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'cambiarDatosFacturacion',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'params' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'params' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'tipo_doc' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'documento' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'razon_social',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'condicion_ante_iva',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'domicilio' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CambiarDatosFacturacionMutation,
    CambiarDatosFacturacionMutationVariables
>;
export const CancelarCuentaDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'cancelarCuenta' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'params' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'InputCancelarParams',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cancelar' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'params' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'params' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CancelarCuentaMutation,
    CancelarCuentaMutationVariables
>;
export const ContratarZenriseDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'contratarZenrise' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'nombre' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dni' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'cuenta_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'token' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'JSON' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'es_tarjeta_credito' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Boolean' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'plan_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contratarZenrise' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'params' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: {
                                                kind: 'Name',
                                                value: 'nombre',
                                            },
                                            value: {
                                                kind: 'Variable',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'nombre',
                                                },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: {
                                                kind: 'Name',
                                                value: 'dni',
                                            },
                                            value: {
                                                kind: 'Variable',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'dni',
                                                },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: {
                                                kind: 'Name',
                                                value: 'cuenta_id',
                                            },
                                            value: {
                                                kind: 'Variable',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'cuenta_id',
                                                },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: {
                                                kind: 'Name',
                                                value: 'token',
                                            },
                                            value: {
                                                kind: 'Variable',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'token',
                                                },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: {
                                                kind: 'Name',
                                                value: 'es_tarjeta_credito',
                                            },
                                            value: {
                                                kind: 'Variable',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'es_tarjeta_credito',
                                                },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: {
                                                kind: 'Name',
                                                value: 'plan_id',
                                            },
                                            value: {
                                                kind: 'Variable',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'plan_id',
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ok' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'mensaje_error',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ContratarZenriseMutation,
    ContratarZenriseMutationVariables
>;
export const CrearUsuarioManualDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'crearUsuarioManual' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'params' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'CrearUsuarioManualmenteParams',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'crearUsuarioManualmente',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'params' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'params' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CrearUsuarioManualMutation,
    CrearUsuarioManualMutationVariables
>;
export const DesbloquearCuentaDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'desbloquearCuenta' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'cuenta_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'testDesbloquearCuenta' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'cuenta_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'cuenta_id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DesbloquearCuentaMutation,
    DesbloquearCuentaMutationVariables
>;
export const ElegirPlanDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'elegirPlan' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'cuenta_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'plan_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'elegirPlan' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'cuenta_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'cuenta_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'plan_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'plan_id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ElegirPlanMutation, ElegirPlanMutationVariables>;
export const FixDatosDesdeLegacyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'fixDatosDesdeLegacy' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fixDatosDesdeLegacy' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    FixDatosDesdeLegacyMutation,
    FixDatosDesdeLegacyMutationVariables
>;
export const IniciarContratacionZenriseDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'iniciarContratacionZenrise' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'cuenta_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'contratacion_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'iniciarContratacionZenrise',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'params' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: {
                                                kind: 'Name',
                                                value: 'cuenta_id',
                                            },
                                            value: {
                                                kind: 'Variable',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'cuenta_id',
                                                },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: {
                                                kind: 'Name',
                                                value: 'contratacion_id',
                                            },
                                            value: {
                                                kind: 'Variable',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'contratacion_id',
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    IniciarContratacionZenriseMutation,
    IniciarContratacionZenriseMutationVariables
>;
export const MandarPlanZenRiseDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'mandarPlanZenRise' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'plan_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mandarPlanZenRise' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'plan_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'plan_id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    MandarPlanZenRiseMutation,
    MandarPlanZenRiseMutationVariables
>;
export const MigracionCancelarDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'migracionCancelar' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'cuenta_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'comentario' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'migracionCancelar' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'cuenta_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'cuenta_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'comentario' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'comentario' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    MigracionCancelarMutation,
    MigracionCancelarMutationVariables
>;
export const MigracionMarcarComoListaDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'migracionMarcarComoLista' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'cuenta_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fecha_proximo_pago' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Date' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'migracionMarcarComoLista',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'cuenta_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'cuenta_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'fecha_proximo_pago',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'fecha_proximo_pago',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    MigracionMarcarComoListaMutation,
    MigracionMarcarComoListaMutationVariables
>;
export const MigracionPasarGestionManualDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'migracionPasarGestionManual' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'cuenta_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'migracionPasarGestionManual',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'cuenta_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'cuenta_id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    MigracionPasarGestionManualMutation,
    MigracionPasarGestionManualMutationVariables
>;
export const ProcesarIpnDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'procesarIPN' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'procesarIPN' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ok' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mensaje' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ProcesarIpnMutation, ProcesarIpnMutationVariables>;
export const TestProcesarWebhookDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'testProcesarWebhook' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'rst' },
                        name: { kind: 'Name', value: 'procesarWebhook' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ok' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mensaje' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    TestProcesarWebhookMutation,
    TestProcesarWebhookMutationVariables
>;
export const RecibirIpnDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'recibirIPN' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'content' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'testSimularIPNRecibido' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'content' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'content' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RecibirIpnMutation, RecibirIpnMutationVariables>;
export const RecibirWebhookDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'recibirWebhook' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'content' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'testSimularWebhookRecibido',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'content' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'content' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RecibirWebhookMutation,
    RecibirWebhookMutationVariables
>;
export const ResetDbDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'resetDb' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'resetDb' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ResetDbMutation, ResetDbMutationVariables>;
export const UpdateDatosTnDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateDatosTN' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'UpdateInfoDesdeTn' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateDatosTnMutation,
    UpdateDatosTnMutationVariables
>;
export const TestCrearPlanInicialDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'testCrearPlanInicial' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'crearPlanInicial' },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    TestCrearPlanInicialMutation,
    TestCrearPlanInicialMutationVariables
>;
export const TestCrearTrialDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'testCrearTrial' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dias' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Int' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'testCrearCuentaEnTrial' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'dias' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'dias' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    TestCrearTrialMutation,
    TestCrearTrialMutationVariables
>;
