import { PropsWithChildren, useState } from 'react';
import {
    AppShell,
    Box,
    Burger,
    Container,
    createStyles,
    Header,
    MantineSize,
    MediaQuery,
    Navbar,
    Space,
    Title,
} from '@mantine/core';
import { JSX } from 'react';
import { theme } from '../Styles/Theme.ts';

const useStyles = createStyles((theme) => {
    return {
        menuRoot: {
            border: 'none',
            padding: '0 25px ',
            height: '100vh',
            backgroundColor: theme.colors.negroLuker[8],
            // padding: '0 !important',
            // borderColor: theme.colors.navBar[9],
        },
        headerRoot: {
            // width: '1180',
            backgroundColor: theme.colors.celesteLuker[1],
            border: 'none',
            display: 'flex',
            padding: '18px 28px',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexShrink: 0,
            '& h1': {
                display: 'flex',
                height: '33px',
                padding: '20px 0px',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexShrink: 0,
            },
        },

        shellMain: {
            backgroundImage: 'url(/fondo.svg)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundColor: '#e9f4fb',
        },
        logoLukerBox: {
            padding: '18px 0',
            '& img': {
                width: '210px',
            },
        },
    };
});

export interface MainLayoutProps {
    titulo: string;
    menu: JSX.Element;
    tiendaNombre?: string;
    containerSize?: MantineSize;
    fluid?: boolean;
}

export function MainLayout(props: PropsWithChildren<MainLayoutProps>) {
    // const legacyDomain = Env.LEGACY_DOMAIN;
    // const [, setLocation] = useLocation();
    const { classes } = useStyles();
    const [opened, setOpened] = useState(false);
    return (
        <AppShell
            layout="alt"
            padding="md"
            navbarOffsetBreakpoint="sm"
            asideOffsetBreakpoint="sm"
            navbar={
                <Navbar
                    hiddenBreakpoint="sm"
                    hidden={!opened}
                    classNames={{
                        root: classes.menuRoot,
                    }}
                    c={'celesteLuker.1'}
                    width={{ sm: 200, lg: 300 }}
                    // width={{ base: 260 }}
                >
                    <MediaQuery
                        largerThan="sm"
                        styles={{ display: 'none' }}
                    >
                        <Burger
                            opened={opened}
                            onClick={() => setOpened((o) => !o)}
                            size="sm"
                            // @ts-ignore
                            color={theme.colors.celesteLuker[1]}
                            mr="xl"
                            style={{ paddingTop: 40, paddingBottom: 40 }}
                        />
                    </MediaQuery>
                    <MediaQuery
                        smallerThan="sm"
                        styles={{ display: 'none' }}
                    >
                        <Box className={classes.logoLukerBox}>
                            <img
                                alt={'Luker'}
                                src="/luker_celeste.svg"
                                width="100%"
                            />
                        </Box>
                    </MediaQuery>
                    {props.menu}
                </Navbar>
            }
            header={
                <Header
                    height={74}
                    classNames={{
                        root: classes.headerRoot,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <MediaQuery
                            largerThan="sm"
                            styles={{ display: 'none' }}
                        >
                            <Burger
                                opened={opened}
                                onClick={() => setOpened((o) => !o)}
                                size="sm"
                                // color={theme.colors.gray[6]}
                                mr="xl"
                            />
                        </MediaQuery>
                        <Title fz={'1.25rem'}>{props.titulo}</Title>
                        <Title fz={'1.25rem'}>{props.tiendaNombre}</Title>
                    </div>
                </Header>
            }
            classNames={{
                main: classes.shellMain,
            }}
        >
            <Container
                size={props.containerSize}
                fluid={props.fluid}
            >
                <Space h="md" />
                {props.children}
            </Container>
        </AppShell>
    );
}
