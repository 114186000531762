import Session from 'supertokens-auth-react/recipe/session';
import { useSessionContextMock } from '../Mocks/UseSessionContextMock.ts';
import { Env } from '../Env.ts';

const mock = Env.MOCK_AUTH === 'true';

//TODO: refactorear para no haya que llamar a esta función en cada lugar (tengo un ejemplo por ahí de como se adorna un hook)
export function getSessionContextFn() {
    if (mock) {
        console.log('Mocking session');
        return useSessionContextMock;
    }
    return Session.useSessionContext;
}
