import { AuthLayout } from '../../Layouts/AuthLayout.tsx';
import {
    Alert,
    Anchor,
    Box,
    Button,
    Paper,
    PasswordInput,
    Stack,
    Text,
    TextInput,
} from '@mantine/core';
import { Logo } from '../../Logo.tsx';
import { IconAlertCircle } from '@tabler/icons-react';
import { Colors } from '../../Utils/Colors.ts';
import { FormErrors, useForm } from '@mantine/form';
import { Values } from './LoginHook.tsx';
import {
    validateEmail,
    // validatePassword,
} from '../../Utils/BasicValidations.ts';
import debug from 'debug';
import { FormEvent } from 'react';
import { useLocation } from 'wouter';

const log = debug('LoginComponent');

export interface LoginProps {
    errorMessage?: string;
    initialValues?: Partial<Values>;
    loading: boolean;
    onSubmit: (values: Values, evt: FormEvent<HTMLFormElement>) => void;
    formErrors?: FormErrors;
}

export function LoginComponent({
    loading,
    initialValues = {},
    errorMessage,
    onSubmit,
    formErrors = {},
}: LoginProps) {
    const [, setLocation] = useLocation();
    const form = useForm({
        initialValues: {
            email: '',
            password: '',
            ...initialValues,
        },
        initialErrors: formErrors,
        validate: {
            email: validateEmail,
            // password: validatePassword,
        },
    });
    log('loading %O', loading);
    // form.setErrors();
    return (
        <AuthLayout dontIncludePaper={true}>
            <Paper
                p={{ base: '1em', xs: '2em' }}
                withBorder
                radius="md"
                shadow="md"
            >
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <Stack>
                        <Logo />
                        <TextInput
                            size={'lg'}
                            label="Email"
                            placeholder="Ingresá tu email"
                            required
                            type={'email'}
                            disabled={loading}
                            {...form.getInputProps('email')}
                        />
                        <PasswordInput
                            size={'lg'}
                            label="Contraseña"
                            placeholder="Ingresá tu contraseña" //Al menos 8 caracteres
                            required
                            disabled={loading}
                            {...form.getInputProps('password')}
                        />
                        <Box style={{ textAlign: 'right' }}>
                            <a
                                href={'/resetearpassword'}
                                onClick={(evt) => {
                                    evt.preventDefault();
                                    setLocation('/resetearpassword');
                                }}
                            >
                                ¿Olvidaste tu clave?
                            </a>
                        </Box>
                        <Button
                            style={
                                {
                                    // backgroundColor: Colors.azul,
                                    // color: Colors.celeste,
                                }
                            }
                            formNoValidate={true}
                            size={'lg'}
                            type={'submit'}
                            fullWidth
                            mt="xl"
                            disabled={loading}
                            loading={loading}
                        >
                            {loading ? 'Accediendo...' : 'Acceder'}
                        </Button>

                        {errorMessage && (
                            <Alert
                                // mx={'1em'}
                                icon={<IconAlertCircle size="1rem" />}
                                color="red"
                            >
                                <Text sx={{ color: 'red', fontWeight: 'bold' }}>
                                    {errorMessage}
                                </Text>
                            </Alert>
                        )}
                    </Stack>
                </form>
            </Paper>
            <Box sx={{ textAlign: 'center', padding: '8px 0' }}>
                <Anchor
                    href={'https://www.tiendanube.com/apps/647/authorize'}
                    // pr={30}
                    c={Colors.celeste}
                    size={14}
                >
                    Ingresar con{' '}
                    <Text
                        component={'span'}
                        c={Colors.celeste}
                        fz={18}
                        weight={800}
                    >
                        Tienda Nube
                    </Text>
                </Anchor>
            </Box>
            {/* TODO: Lo comento hasta que lo implemente
            <Box sx={{ textAlign: 'center'}}>
                <Anchor
                    href={'/resetearpassword'}
                    component={Link}
                    c={Colors.celeste}
                    align={'center'}
                    size={14}
                >
                    ¿Olvidaste tu contraseña?
                </Anchor>
            </Box>*/}
        </AuthLayout>
    );
}
