/**
 * Esto es temporal, hasta que aprenda a usar los colores de Mantine
 */

/**
 * @deprecated
 */
export const Colors = {
    celeste: '#C0C8E1',
    azul: '#384FA1',
    azulOscuro: '#4249A7',
    fondoNormal: '#e9effb',
};
