import { SuperTokensWrapper } from 'supertokens-auth-react';
import { Redirect, Route, Switch } from 'wouter';
import * as Sentry from '@sentry/react';

import './App.css';

import { DefaultRoute } from './Auth/DefaultRoute.tsx';
import { MantineProvider } from '@mantine/core';
import { InitSupertokens } from './Auth/Logic/InitSupertokens.ts';
import { theme } from './Styles/Theme.ts';
import { ResetPasswordRequest } from './Auth/ResetPassword/ResetPasswordRequest.tsx';
import { RefreshSession } from './Auth/RefreshSession.tsx';
import { LoginController } from './Auth/Login/LoginController.tsx';
import { Logout } from './Auth/Logout.tsx';
import { InitSentry } from './Utils/Sentry.ts';
import { MigracionController } from './Auth/Migracion/MigracionController.tsx';
import { CrearUsuarioController } from './Auth/CrearUsuario/CrearUsuarioController.tsx';
import { Env } from './Env.ts';
import { Checker } from './Auth/Checker.tsx';
import { Dashboard } from './Customer/Dashboard.tsx';
import { OwnApolloProvider } from './OwnApolloProvider.tsx';
import { Perfil } from './Customer/Perfil.tsx';
import { lazy, Suspense } from 'react';
import { AdminPage } from './Admin/Admin/AdminPage.tsx';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import {
    ConfigCatProvider,
    createConsoleLogger,
    LogLevel,
} from 'configcat-react';
import { Ingresando } from './Auth/Ingresando.tsx';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ResetPassword } from './Auth/ResetPassword/ResetPassword.tsx';

if (import.meta.env.DEV) {
    // Adds messages only in a dev environment
    loadDevMessages();
}
//Cargo los mensajes de error en prod también, porque no anda bien el texto si no
loadErrorMessages();

InitSentry();

InitSupertokens();

const FacturacionPage = lazy(() => import('./Customer/FacturacionPage.tsx'));
const CargarTarjetaPage = lazy(
    () => import('./Customer/CargarTarjeta/CargarTarjetaPage.tsx')
);
const EstadisticasPage = lazy(
    () => import('./Customer/Estadisticas/EstadisticasPage.tsx')
);
const EstadisticasComoAdminPage = lazy(
    () => import('./Customer/Estadisticas/EstadisticasComoAdminPage.tsx')
);
const FacturacionComoAdminPage = lazy(
    () => import('./Customer/FacturacionComoAdminPage.tsx')
);
const AdminCuentaPage = lazy(() => import('./Admin/AdminCuentaPage.tsx'));
const AdminInvoicesPage = lazy(() => import('./Admin/AdminInvoicesPage.tsx'));
const AdminProcesoTerminarMigracionPage = lazy(
    () => import('./Admin/AdminProcesoTerminarMigracionPage.tsx')
);
const AdminBloqueadasPage = lazy(
    () => import('./Admin/AdminBloqueadasPage.tsx')
);
const AdminDashboardPage = lazy(
    () => import('./Admin/Dashboard/AdminDashboardPage.tsx')
);
const AdminUsuariosPage = lazy(() => import('./Admin/AdminUsuariosPage.tsx'));
const AdminSuscripcionesPage = lazy(
    () => import('./Admin/AdminSuscripcionesPage.tsx')
);
const AdminCuentasPage = lazy(() => import('./Admin/AdminCuentasPage.tsx'));
const AdminContratacionesPage = lazy(
    () => import('./Admin/AdminContratacionesPage.tsx')
);
const AdminPlanesPage = lazy(() => import('./Admin/AdminPlanesPage.tsx'));
const AdminPlanPage = lazy(() => import('./Admin/AdminPlanPage.tsx'));
const AdminCobranzasPage = lazy(() => import('./Admin/AdminCobranzasPage.tsx'));
const AdminTrialsPage = lazy(() => import('./Admin/AdminTrialsPage.tsx'));
const AdminMigracionesPage = lazy(
    () => import('./Admin/AdminMigracionesPage.tsx')
);

const queryClient = new QueryClient();

function App() {
    console.log(`Luker Console. Version: ${Env.PACKAGE_VERSION}`, {
        SENTRY_DSN: Env.SENTRY_DSN,
        VITE_CONFIG_CAT: Env.VITE_CONFIG_CAT,
        MODE: Env.MODE,
        ENV: Env,
    });
    //TODO: unificar todos los wrappers y providers en un solo componente (para que no cambie los tabs de app cuando se agrega uno)
    return (
        <Sentry.ErrorBoundary showDialog>
            <QueryClientProvider client={queryClient}>
                <ConfigCatProvider
                    sdkKey={Env.VITE_CONFIG_CAT}
                    options={{ logger: createConsoleLogger(LogLevel.Info) }}
                >
                    <MantineProvider
                        withGlobalStyles
                        withNormalizeCSS
                        theme={theme}
                    >
                        <SuperTokensWrapper>
                            <OwnApolloProvider>
                                <Switch>
                                    <Route
                                        path={'/resetearpassword'}
                                        component={ResetPasswordRequest}
                                    />
                                    {/*TODO: hacer un test case en auth que confirme que tiene la url bien para el email*/}
                                    <Route
                                        path={'/resetearpasswordconfirm'}
                                        component={() => {
                                            const url = new URL(
                                                window.location.href
                                            );
                                            console.log(
                                                url.searchParams.toString()
                                            );
                                            if (
                                                !url.searchParams.get('token')
                                            ) {
                                                return (
                                                    <Redirect to={'/login'} />
                                                );
                                            }
                                            return <ResetPassword />;
                                        }}
                                    />
                                    <Route
                                        path={'/crearusuario'}
                                        component={CrearUsuarioController}
                                    />
                                    <Route
                                        path={'/refresh'}
                                        component={RefreshSession}
                                    />
                                    <Route
                                        path={'/migracion'}
                                        component={MigracionController}
                                    />
                                    <Route
                                        path={'/login'}
                                        component={LoginController}
                                    />
                                    <Route
                                        path={'/ingresando'}
                                        component={Ingresando}
                                    />
                                    <Route
                                        path={'/logout'}
                                        component={Logout}
                                    />
                                    <Route
                                        path={'/perfil'}
                                        component={() => (
                                            <Checker onlyUser>
                                                <Perfil />
                                            </Checker>
                                        )}
                                    />
                                    <Route
                                        path={'/facturacion'}
                                        component={() => {
                                            return (
                                                <Suspense>
                                                    <FacturacionPage />
                                                </Suspense>
                                            );
                                        }}
                                    />
                                    <Route
                                        path={'/estadisticas'}
                                        component={() => {
                                            return (
                                                <Suspense>
                                                    <EstadisticasPage />
                                                </Suspense>
                                            );
                                        }}
                                    />
                                    <Route
                                        path={'/contratar/:cuenta_id'}
                                        component={(props) => {
                                            return (
                                                <Suspense>
                                                    <CargarTarjetaPage
                                                        {...props}
                                                    />
                                                </Suspense>
                                            );
                                        }}
                                    />
                                    <Route
                                        path={'/dashboard'}
                                        component={() => (
                                            <Checker onlyUser>
                                                <Dashboard />
                                            </Checker>
                                        )}
                                    />
                                    <Route
                                        path={'/admin/dashboard'}
                                        component={() => (
                                            <Suspense>
                                                <AdminDashboardPage />
                                            </Suspense>
                                        )}
                                    />
                                    <Route
                                        path={
                                            '/admin/comocliente/facturacion/:id'
                                        }
                                        component={(props) => {
                                            return (
                                                <Suspense>
                                                    <FacturacionComoAdminPage
                                                        {...props}
                                                    />
                                                </Suspense>
                                            );
                                        }}
                                    />
                                    <Route
                                        path={
                                            '/admin/comocliente/estadisticas/:id'
                                        }
                                        component={(props) => {
                                            return (
                                                <Suspense>
                                                    <EstadisticasComoAdminPage
                                                        {...props}
                                                    />
                                                </Suspense>
                                            );
                                        }}
                                    />
                                    <Route
                                        path={'/admin/bloqueadas'}
                                        component={() => (
                                            <Suspense>
                                                <AdminBloqueadasPage />
                                            </Suspense>
                                        )}
                                    />
                                    <Route
                                        path={'/admin/cuentas'}
                                        component={() => (
                                            <Suspense>
                                                <AdminCuentasPage />
                                            </Suspense>
                                        )}
                                    />
                                    <Route
                                        path={'/admin/suscripciones'}
                                        component={() => (
                                            <Suspense>
                                                <AdminSuscripcionesPage />
                                            </Suspense>
                                        )}
                                    />
                                    <Route
                                        path={'/admin/invoices'}
                                        component={() => (
                                            <Suspense>
                                                <AdminInvoicesPage />
                                            </Suspense>
                                        )}
                                    />
                                    <Route
                                        path={'/admin/contrataciones'}
                                        component={() => (
                                            <Suspense>
                                                <AdminContratacionesPage />
                                            </Suspense>
                                        )}
                                    />
                                    <Route
                                        path={'/admin/usuarios'}
                                        component={() => (
                                            <Suspense>
                                                <AdminUsuariosPage />
                                            </Suspense>
                                        )}
                                    />
                                    <Route
                                        path={'/admin/planes'}
                                        component={() => (
                                            <Suspense>
                                                <AdminPlanesPage />
                                            </Suspense>
                                        )}
                                    />
                                    <Route
                                        path={'/admin/planes/:id'}
                                        component={(props) => (
                                            <Suspense>
                                                <AdminPlanPage {...props} />
                                            </Suspense>
                                        )}
                                    />
                                    <Route
                                        path={'/admin/trials'}
                                        component={() => (
                                            <Suspense>
                                                <AdminTrialsPage />
                                            </Suspense>
                                        )}
                                    />
                                    <Route
                                        path={'/admin/migraciones'}
                                        component={() => (
                                            <Suspense>
                                                <AdminMigracionesPage />
                                            </Suspense>
                                        )}
                                    />
                                    <Route
                                        path={'/admin/terminarmigraciones'}
                                        component={() => (
                                            <Suspense>
                                                <AdminProcesoTerminarMigracionPage />
                                            </Suspense>
                                        )}
                                    />
                                    <Route
                                        path={'/admin/cobranzas'}
                                        component={() => (
                                            <Suspense>
                                                <AdminCobranzasPage />
                                            </Suspense>
                                        )}
                                    />
                                    <Route
                                        path={'/admin/cobranzas/cuenta/:id'}
                                        component={() => (
                                            <Suspense>
                                                {<AdminCuentaPage />}
                                            </Suspense>
                                        )}
                                    />
                                    <Route
                                        path={'/admin'}
                                        component={() => <AdminPage />}
                                    />
                                    <Route component={DefaultRoute} />
                                </Switch>
                            </OwnApolloProvider>
                        </SuperTokensWrapper>
                    </MantineProvider>
                </ConfigCatProvider>
            </QueryClientProvider>
        </Sentry.ErrorBoundary>
    );
}

export default App;
