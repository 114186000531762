/* istanbul ignore file */

import { iconFacturacion, iconHelp, iconPanel, iconStatsUp } from './Icons.tsx';
import { Menu, MenuItem } from './Menu.tsx';
import { IconArrowBarToLeft } from '@tabler/icons-react';
// import debug from 'debug';

// const log = debug('MenuUsuario');

export function MenuUsuario() {
    const menuItems: MenuItem[] = [
        {
            label: 'ESTADÍSTICAS',
            icon: iconStatsUp,
            url: '/estadisticas',
        },
        {
            label: 'CONFIGURACION',
            icon: iconPanel,
            legacyUrl: '/settings',
        },
        // {
        //     label: 'PERFIL',
        //     icon: iconPanel,
        //     url: '/perfil',
        // },
        {
            label: 'FACTURACION',
            icon: iconFacturacion,
            url: '/facturacion',
        },
        {
            label: 'MANUAL',
            newTab: 'https://luker.supporthero.io/',

            icon: iconHelp,
        },
        {
            label: 'SALIR',
            icon: <IconArrowBarToLeft />,
            url: '/logout',
        },
    ];

    return (
        <>
            <Menu menuItems={menuItems} />
        </>
    );
}
