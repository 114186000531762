import { useMutation } from '@tanstack/react-query';
import { changePasswordWithToken } from './Api/ChangePasswordWithToken.tsx';
import { useForm } from '@mantine/form';
import { validatePassword } from '../../Utils/BasicValidations.ts';
import { Values } from './ResetPassword.tsx';

export function ResetPasswordHook() {
    const mutation = useMutation({
        mutationFn: changePasswordWithToken,
    });

    function handle(values: Values) {
        mutation.mutate(values.password);
    }

    const form = useForm<Values>({
        initialValues: {
            password: '',
        },
        validate: {
            password: validatePassword,
        },
    });
    return {
        mutation,
        handle,
        form,
    };
}
