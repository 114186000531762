import { createContext, PropsWithChildren } from 'react';
import { AccessTokenPayload } from '../Types/Varios.ts';

export const UserMetadata = createContext<AccessTokenPayload>(
    {} as AccessTokenPayload
);

interface UserMetadataWrapperProps {
    payload: AccessTokenPayload;
}

export function UserMetadataWrapper(
    props: PropsWithChildren<UserMetadataWrapperProps>
) {
    return (
        <UserMetadata.Provider value={props.payload}>
            {props.children}
        </UserMetadata.Provider>
    );
}
