import { AuthLayout } from '../Layouts/AuthLayout.tsx';
import { Center } from '@mantine/core';
import { useEffect } from 'react';
import { redirectToLegacyHome } from './Logic/Rutas.ts';

export function Ingresando() {
    useEffect(() => {
        redirectToLegacyHome();
    }, []);
    return (
        <AuthLayout>
            <Center>Ingresando</Center>
        </AuthLayout>
    );
}
