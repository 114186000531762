import { AuthLayout } from '../Layouts/AuthLayout.tsx';
import { Center } from '@mantine/core';

export function SimpleError({ errorMessage }: { errorMessage: string }) {
    return (
        <AuthLayout>
            <Center>{errorMessage}</Center>
        </AuthLayout>
    );
}
