import { MantineThemeOverride, Tuple } from '@mantine/core';

export const theme: MantineThemeOverride = {
    fontFamily: `'Montserrat', sans-serif`,
    //Esta es para ids, números y fechas
    // fontFamily: `'Source Code Pro', monospace`,
    headings: {
        fontFamily: 'Montserrat, monospace',
        fontWeight: 600,
    },
    colors: {
        /**
         * @deprecated
         */
        darkBlue: ['#4249A7'],
        //Parece que necesita si o si 10 colores...
        /**
         * @deprecated
         */
        brand: [
            '#A2A8BB',
            '#8C94B2',
            '#7581AC',
            '#5D6EAA',
            '#495DA6',
            '#384FA1',
            '#3C4C87',
            '#3D4973',
            '#3C4463',
            '#393F55',
        ],
        azulLuker: Object.values({
            50: '#e8efff',
            100: '#c4cef0',
            200: '#9faee1',
            300: '#7a8dd2',
            400: '#556dc4',
            500: '#3b54aa',
            600: '#2d4185',
            700: '#1f2e61',
            800: '#111c3d',
            900: '#04081b',
        }) as Tuple<string, 10>,
        celesteLuker: Object.values({
            50: '#ecf0fc',
            100: '#cbd2e6',
            200: '#aab4d3',
            300: '#8895c1',
            400: '#6777b0',
            500: '#4d5e96',
            600: '#3c4976',
            700: '#2a3454',
            800: '#191f34',
            900: '#060a15',
        }) as Tuple<string, 10>,
        negroLuker: Object.values({
            50: '#e9effb',
            100: '#cdd4e5',
            200: '#adbad0',
            300: '#8e9fbe',
            400: '#6e86ab',
            500: '#556e92',
            600: '#415772',
            700: '#2f4052',
            800: '#1a2732',
            900: '#050c15',
        }) as Tuple<string, 10>,
        doradoLuker: Object.values({
            50: '#fff2dc',
            100: '#ffd9b1',
            200: '#fbc282',
            300: '#f8aa53',
            400: '#f59123',
            500: '#dc780a',
            600: '#ab5d05',
            700: '#7b4202',
            800: '#4b2700',
            900: '#1e0b00',
        }) as Tuple<string, 10>,
        rojoLuker: Object.values({
            50: '#ffe2e2',
            100: '#ffb1b3',
            200: '#ff8082',
            300: '#fe4e50',
            400: '#fc1c20',
            500: '#e30306',
            600: '#b10004',
            700: '#7f0002',
            800: '#4f0000',
            900: '#200000',
        }) as Tuple<string, 10>,
        /**
         * @deprecated
         */
        navBar: [
            '#e9effb',
            '#cdd4e5',
            '#adbad0',
            '#8e9fbe',
            '#6e86ab',
            '#556e92',
            '#415772',
            '#2f4052',
            '#1a2732',
            '#050c15',
        ],
    },
    primaryColor: 'azulLuker',
    primaryShade: 4,
    globalStyles: (theme) => {
        return {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            body: {
                ...theme.fn.fontStyles(),
                // backgroundColor: '#cbd2e6',
                padding: '0 !important',
            },
        };
    },
};
