import { AuthLayout } from '../Layouts/AuthLayout.tsx';
import { Center } from '@mantine/core';

export function Forbidden() {
    return (
        <AuthLayout>
            <Center>No tiene permisos para acceder a esta sección</Center>
        </AuthLayout>
    );
}
