import { MainLayout } from '../Layouts/MainLayout.tsx';
import { MenuUsuario } from '../Layouts/MenuUsuario.tsx';

export function Perfil() {
    return (
        <MainLayout
            menu={<MenuUsuario />}
            titulo={'Perfil'}
        ></MainLayout>
    );
}
