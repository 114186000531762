import * as Sentry from '@sentry/react';

export function SafeUrl(url: string | undefined, defaultUrl: string): string {
    if (!url) {
        return defaultUrl;
    }
    try {
        return new URL(url).toString();
    } catch {
        //probamos con un decode
        try {
            return new URL(decodeURIComponent(url)).toString();
        } catch (error: unknown) {
            Sentry.captureException(error);
            return defaultUrl;
        }
    }
}
