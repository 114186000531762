import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import { Center } from '@mantine/core';
import { es } from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';
import * as Sentry from '@sentry/react';
setDefaultOptions({ locale: es });

/**
 * Poner la pantalla de error general acá
 */
ReactDOM.createRoot(document.querySelector('#root')!).render(
    <Sentry.ErrorBoundary
        showDialog
        fallback={
            <Center>
                <div>Ha ocurrido un error</div>
            </Center>
        }
    >
        <App />
    </Sentry.ErrorBoundary>
);
