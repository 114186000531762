export class FieldError extends Error {
    public field: string;
    public fieldMessage: string;

    constructor({
        field,
        fieldMessage,
    }: {
        field: string;
        fieldMessage: string;
    }) {
        super(`ha ocurrido un error: ${fieldMessage}`);
        this.field = field;
        this.fieldMessage = fieldMessage;
    }
}
