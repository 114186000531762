import {
    Title,
    Card,
    Space,
    MantineColor,
    createStyles,
    CardProps,
    Flex,
    ActionIcon,
    SpacingValue,
    SystemProp,
    LoadingOverlay,
} from '@mantine/core';
import { JSX, PropsWithChildren } from 'react';
import { IconRefresh } from '@tabler/icons-react';
import { NavigationProgress, nprogress } from '@mantine/nprogress';

export interface PanelProps {
    titulo: string;
    bgTitulo?: MantineColor;
    cardProps?: Omit<CardProps, 'children'>;
    extras?: JSX.Element;
    refreshFn?: () => Promise<unknown>;
    marginBottom?: SystemProp<SpacingValue>;
    loading?: boolean;
    minHeight?: number;
}

const useStyles = createStyles<string, Aux>((_theme, { minHeight }) => ({
    panelRoot: {
        minHeight: minHeight,
    },
}));

interface Aux {
    minHeight?: number;
}

export function Panel({
    titulo,
    children,
    bgTitulo,
    cardProps,
    extras,
    refreshFn,
    marginBottom,
    loading,
    minHeight,
}: PropsWithChildren<PanelProps>) {
    const { classes } = useStyles({ minHeight: minHeight ?? 300 });
    async function refresh() {
        if (!refreshFn) return;
        nprogress.reset();
        nprogress.start();
        try {
            console.log('refrescando');
            await refreshFn();
        } finally {
            nprogress.complete();
        }
    }
    return (
        <>
            <NavigationProgress />
            <Card
                {...cardProps}
                // maw={690}
                radius="md"
                shadow="sm"
                mb={marginBottom ?? '1rem'}
                className={classes.panelRoot}
            >
                {loading ? <LoadingOverlay visible={true} /> : null}
                {/* top, right, left margins are negative – -1 * theme.spacing.xl */}
                <Card.Section
                    bg={bgTitulo ?? 'celesteLuker.1'}
                    c={'celesteLuker.9'}
                    py="xs"
                    inheritPadding
                >
                    <Flex
                        justify={'space-between'}
                        align={'center'}
                        gap={5}
                    >
                        {refreshFn && (
                            <ActionIcon
                                // variant="filled"
                                color={'azulLuker'}
                                aria-label="Refrescar"
                                title="Refrescar"
                                onClick={() => void refresh()}
                            >
                                <IconRefresh />
                            </ActionIcon>
                        )}
                        <Title
                            sx={{ flexGrow: 2 }}
                            order={2}
                        >
                            {titulo}
                        </Title>
                        {extras}
                    </Flex>
                </Card.Section>
                <Space h={'md'} />
                {children}
            </Card>
        </>
    );
}
