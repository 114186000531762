import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';
import Session from 'supertokens-auth-react/recipe/session';
import SuperTokens from 'supertokens-auth-react';
import { Env } from '../../Env.ts';

export function InitSupertokens() {
    SuperTokens.init({
        // enableDebugLogs: true,
        appInfo: {
            // learn more about this on https://supertokens.com/docs/emailpassword/appinfo
            appName: 'luker',
            apiDomain: Env.ST_API_DOMAIN,
            websiteDomain: Env.ST_WEBSITE_DOMAIN,
            // Esto es si el back está en un nivel más adentro en las rutas, onda /auth. Para nosotros es '/'
            apiBasePath: '/',
            // Este creo que era opcional, solo para usarse con su UI built in, pruebo sacarlo
            // websiteBasePath: '/auth',
            // Este es para cuando el back usa los entornos de ApiGateway, no es nuestro caso
            // apiGatewayPath: '/prod',
        },
        recipeList: [
            EmailPassword.init({}),
            Session.init({
                sessionTokenFrontendDomain:
                    Env.VITE_ST_COOKIE_DOMAIN ?? '.luker.io',
                sessionTokenBackendDomain:
                    Env.VITE_ST_COOKIE_DOMAIN ?? '.luker.io',
            }),
        ],
    });
}
