import { MainLayout } from '../Layouts/MainLayout.tsx';
import { Panel } from '../Primitivas/Panel.tsx';
import { MenuUsuario } from '../Layouts/MenuUsuario.tsx';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DashboardProps {}

// eslint-disable-next-line no-empty-pattern
export function Dashboard({}: DashboardProps) {
    return (
        <MainLayout
            titulo={'Dashboard'}
            menu={<MenuUsuario />}
        >
            <Panel titulo={'Ejemplo'}>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </Panel>
        </MainLayout>
    );
}
