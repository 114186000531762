import isLength from 'validator/es/lib/isLength';
import isEmail from 'validator/es/lib/isEmail';

export function validatePassword(value: string): string | undefined {
    return isLength(value, { min: 8 })
        ? undefined
        : 'La contraseña debe tener al menos 8 dígitos';
}

export function validateEmail(value: string): string | undefined {
    return isEmail(value) ? undefined : 'No es un email válido';
}
