import {
    ApolloCache,
    DefaultContext,
    MutationTuple,
    OperationVariables,
    useMutation,
} from '@apollo/client';
import { Button, ButtonProps } from '@mantine/core';
import { PropsWithChildren, useCallback } from 'react';
import { NavigationProgress, nprogress } from '@mantine/nprogress';
import { ErrorModal } from '../Common/ErrorModal.tsx';

interface PropsComon {
    buttonProps?: ButtonProps;
    useNProgress?: boolean;
    disabled?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component?: any;
}

interface PropsOpcion1<
    TData = unknown,
    TVariables = OperationVariables,
    TContext = DefaultContext,
    TCache extends ApolloCache<unknown> = ApolloCache<unknown>
> extends PropsComon {
    mutation: () => unknown;
    mutationResult: ReturnType<
        typeof useMutation<TData, TVariables, TContext, TCache>
    >[1];
}
interface PropsOpcion2<
    TData = unknown,
    TVariables = OperationVariables,
    TContext = DefaultContext,
    TCache extends ApolloCache<unknown> = ApolloCache<unknown>
> extends PropsComon {
    useMutationResult: MutationTuple<TData, TVariables, TContext, TCache>;
}

export function MutationButton(
    props: PropsWithChildren<PropsOpcion1 | PropsOpcion2>
) {
    const mutation =
        'useMutationResult' in props
            ? props.useMutationResult[0]
            : props.mutation;
    const mutationResult =
        'useMutationResult' in props
            ? props.useMutationResult[1]
            : props.mutationResult;
    const buttonProps = props.buttonProps;
    const children = props.children;
    const callback = useCallback(async () => {
        nprogress.reset();
        nprogress.start();
        try {
            await mutation();
        } finally {
            nprogress.complete();
        }
    }, [mutation]);
    return (
        <>
            <NavigationProgress />
            {mutationResult.error && (
                <ErrorModal
                    opened={true}
                    close={() => {
                        mutationResult.reset();
                    }}
                    message={JSON.stringify(mutationResult.error, null, 2)}
                />
            )}
            <Button
                {...buttonProps}
                onClick={callback}
                disabled={props.disabled}
                loading={mutationResult.loading}
                //eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                component={props.component}
            >
                {children}
            </Button>
        </>
    );
}
