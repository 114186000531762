import { PropsWithChildren } from 'react';
import { Container, Global, Paper, Stack } from '@mantine/core';
import { Colors } from '../Utils/Colors.ts';
import { Logo } from '../Logo.tsx';

export interface AuthLayoutProps {
    dontIncludePaper?: boolean;
}

export function AuthLayout({
    dontIncludePaper = false,
    children,
}: PropsWithChildren<AuthLayoutProps>) {
    return (
        <>
            <Global
                styles={(_theme) => ({
                    body: {
                        backgroundColor: Colors.azulOscuro,
                    },
                })}
            />
            <Container my={'2em'}>
                <Container
                    w={{ base: '100%', xs: 440 }}
                    px={{ base: 0 }}
                >
                    {dontIncludePaper ? (
                        children
                    ) : (
                        <Paper
                            p={{ base: '1em', xs: '2em' }}
                            withBorder
                            radius="md"
                            shadow="md"
                        >
                            <Logo />
                            <Stack
                                style={{ minHeight: 200 }}
                                justify={'center'}
                            >
                                {children}
                            </Stack>
                        </Paper>
                    )}
                </Container>
            </Container>
        </>
    );
}
