import { FormErrors } from '@mantine/form';

export function TransformSuperTokensFormErrors(
    formFields: {
        id: string;
        error: string;
    }[]
): FormErrors {
    const salida: FormErrors = {};
    for (const formField of formFields) {
        salida[formField.id] = formField.error;
    }
    return salida;
}
