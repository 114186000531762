import { PropsWithChildren } from 'react';
import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    createHttpLink,
    InMemoryCache,
} from '@apollo/client';
import { getSessionContextFn } from './Auth/UseSessionContext.ts';
import { Env } from './Env.ts';
import { AccessTokenPayload } from './Types/Varios.ts';
import { AuthLayout } from './Layouts/AuthLayout.tsx';
import { Center } from '@mantine/core';

const useSessionContext = getSessionContextFn();

/**
 * Esto es como una alternativa a usar directametne ApolloProvider.
 *
 * Es un buen lugar para encapsular el header
 *
 * TODO: creo que todo esto se podría simplificar con https://www.apollographql.com/docs/react/api/link/apollo-link-context (lo que entiendo que haría es pedir el token en cada request, y soporta asyncronico, así que lo podemos sacar de la sesión
 *
 * @param props
 * @constructor
 */
export function OwnApolloProvider(props: PropsWithChildren) {
    const checkingSession = useSessionContext();
    if (checkingSession.loading)
        return (
            <AuthLayout>
                <Center>cargando sesión...</Center>
            </AuthLayout>
        );

    if (!checkingSession.doesSessionExist) return props.children;

    const client = new ApolloClient({
        cache: new InMemoryCache({
            typePolicies: {
                Customer: {
                    keyFields: ['cid'],
                },
                //Probando si esto ayuda
                pagos: {
                    keyFields: ['import_id'],
                },
            },
        }),
        link:
            Env.USE_TOKEN_OR_COOKIES === 'token'
                ? getConfigConToken(
                      (checkingSession.accessTokenPayload as AccessTokenPayload)
                          .jwt
                  )
                : getConfigConCookies(),
    });
    return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}

function getConfigConToken(token: string): ApolloLink {
    return createHttpLink({
        uri: Env.GATEWAY_URL,
        //Si no entendí mal, esto es para que mande las cookies en las consultas
        credentials: 'omit',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}

function getConfigConCookies(): ApolloLink {
    return createHttpLink({
        uri: Env.GATEWAY_URL,
        //Si no entendí mal, esto es para que mande las cookies en las consultas
        credentials: 'include',
    });
}
