import { MigracionComponent } from './MigracionComponent.tsx';
import { MigracionHook } from './MigracionHook.tsx';
import { SimpleError } from '../SimpleError.tsx';

export function MigracionController() {
    const searchParams = new URLSearchParams(document.location.search);
    const cid = searchParams.get('cid') ?? undefined;
    const uid = searchParams.get('uid');
    const super_admin = searchParams.get('super_admin') ?? undefined;
    const nombre = searchParams.get('nombre') ?? undefined;
    const emailSugerido = searchParams.get('email') ?? undefined;

    if (super_admin !== 'true' && !cid) {
        return <SimpleError errorMessage={'Ha ocurrido un error'} />;
    }

    if (!uid) {
        return <SimpleError errorMessage={'Ha ocurrido un error'} />;
    }

    return (
        <MigracionComponent
            {...MigracionHook({
                cid,
                uid,
                super_admin,
            })}
            emailSugerido={emailSugerido}
            nombre={nombre}
        />
    );
}
