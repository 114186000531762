import {
    Alert,
    Box,
    Button,
    Paper,
    Stack,
    TextInput,
    Text,
} from '@mantine/core';
import { AuthLayout } from '../../Layouts/AuthLayout.tsx';
import { Logo } from '../../Logo.tsx';
import { ResetPasswordRequestHook } from './ResetPasswordRequestHook.tsx';
import { IconAlertCircle } from '@tabler/icons-react';

export interface Values {
    email: string;
}

function ResetPasswordRequestSuccess() {
    return (
        <AuthLayout dontIncludePaper>
            <Paper
                p={{ base: '1em', xs: '2em' }}
                withBorder
                radius="md"
                shadow="md"
            >
                <Stack>
                    <Logo />
                    <Box>
                        <p>
                            Te enviamos un correo con un botón para que puedeas
                            resetear tu clave.
                        </p>
                        <p>
                            Al hacer click en ese botón, te va a llevar a un
                            formulario para que puedas ingresar una nueva clave
                        </p>
                    </Box>
                </Stack>
            </Paper>
        </AuthLayout>
    );
}

export function ResetPasswordRequest() {
    const { mutation, form, handle } = ResetPasswordRequestHook();
    if (mutation.error) {
        console.error(mutation.error);
    }
    if (mutation.isSuccess) return <ResetPasswordRequestSuccess />;
    return (
        <AuthLayout dontIncludePaper>
            <Paper
                p={{ base: '1em', xs: '2em' }}
                withBorder
                radius="md"
                shadow="md"
            >
                <form onSubmit={form.onSubmit(handle)}>
                    <Stack>
                        <Logo />
                        <Box>
                            Ingresá tu mail, y vamos a mandar un link para que
                            puedas resetear tu clave
                        </Box>
                        <TextInput
                            size={'lg'}
                            label="Email"
                            placeholder="Ingresá tu email"
                            required
                            type={'email'}
                            disabled={mutation.isPending}
                            {...form.getInputProps('email')}
                        />
                        <Button
                            style={
                                {
                                    // backgroundColor: Colors.azul,
                                    // color: Colors.celeste,
                                }
                            }
                            formNoValidate={true}
                            size={'lg'}
                            type={'submit'}
                            fullWidth
                            mt="xl"
                            // disabled={loading}
                            loading={mutation.isPending}
                        >
                            {'Enviar link al email'}
                        </Button>
                    </Stack>
                </form>
                {mutation.error && (
                    <Alert
                        // mx={'1em'}
                        icon={<IconAlertCircle size="1rem" />}
                        color="red"
                    >
                        <Text sx={{ color: 'red', fontWeight: 'bold' }}>
                            {mutation.error.message}
                        </Text>
                    </Alert>
                )}
            </Paper>
        </AuthLayout>
    );
}
