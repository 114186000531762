import { AuthLayout } from '../../Layouts/AuthLayout.tsx';
import { Alert, Button, PasswordInput, Stack, TextInput } from '@mantine/core';
import { Colors } from '../../Utils/Colors.ts';
import { IconAlertCircle } from '@tabler/icons-react';
import { FormErrors, useForm } from '@mantine/form';
import { Values } from './CrearUsuarioHook.tsx';
import {
    validateEmail,
    validatePassword,
} from '../../Utils/BasicValidations.ts';

export interface CrearUsuarioComponentProps {
    initialValues?: Partial<Values>;
    nombre?: string;
    mostrarNombreTienda?: boolean;
    loading: boolean;
    errorMessage?: string;
    onSubmit: (values: Values) => void;
    emailSugerido?: string;
    formErrors?: FormErrors;
}

export function CrearUsuarioComponent({
    nombre,
    mostrarNombreTienda = false,
    loading,
    errorMessage,
    onSubmit,
    emailSugerido,
    formErrors,
    initialValues,
}: CrearUsuarioComponentProps) {
    // noinspection DuplicatedCode TEMPORARIO POR LA MIGRACION
    const form = useForm({
        initialValues: {
            email: emailSugerido ? decodeURIComponent(emailSugerido) : '',
            password: '',
            ...initialValues,
        },
        initialErrors: formErrors,
        validate: {
            email: validateEmail,
            password: validatePassword,
        },
    });
    return (
        <AuthLayout>
            {errorMessage && (
                <Alert
                    mx={'1em'}
                    icon={<IconAlertCircle size="1rem" />}
                    title="Error"
                    color="red"
                >
                    {errorMessage}
                </Alert>
            )}
            <form onSubmit={form.onSubmit(onSubmit)}>
                <Stack>
                    {nombre && mostrarNombreTienda && (
                        <TextInput
                            size={'lg'}
                            label="Tienda"
                            placeholder=""
                            readOnly={true}
                            disabled={loading}
                            value={nombre}
                        />
                    )}

                    <TextInput
                        size={'lg'}
                        label="Email"
                        placeholder="Ingresá tu email"
                        required
                        disabled={loading}
                        {...form.getInputProps('email')}
                    />
                    <PasswordInput
                        size={'lg'}
                        label="Contraseña"
                        placeholder="Al menos 8 caracteres" //Al menos 8 caracteres
                        required
                        disabled={loading}
                        {...form.getInputProps('password')}
                    />
                    <Button
                        style={{
                            backgroundColor: Colors.azul,
                            // color: Colors.celeste,
                        }}
                        formNoValidate={true}
                        size={'lg'}
                        type={'submit'}
                        fullWidth
                        mt="xl"
                        disabled={loading}
                        loading={loading}
                    >
                        {loading ? 'Guardando...' : 'Confirmar'}
                    </Button>
                </Stack>
            </form>
        </AuthLayout>
    );
}
