import { signUp } from 'supertokens-web-js/recipe/emailpassword';
import { FormErrors, isNotEmpty } from '@mantine/form';
import { TransformSuperTokensFormErrors } from '../../../Utils/TransformSuperTokensFormErrors.ts';
import assert from 'assert';
import isEmail from 'validator/es/lib/isEmail';
import isLength from 'validator/es/lib/isLength';

export interface CreatureUserParams {
    email: string;
    password: string;
    uid: string;
    cid?: string;
    is_admin: boolean;
}

export type CreatureUserReturn = Promise<
    | { status: 'ok' }
    | { status: 'error'; message: 'FORM_ERRORS'; formErrors: FormErrors }
>;

export async function CreateUser({
    email,
    password,
    uid,
    cid,
}: CreatureUserParams): CreatureUserReturn {
    assert(isNotEmpty(email));
    assert(isEmail(email));
    assert(isNotEmpty(password));
    assert(isLength(password, { min: 8 }));

    const response = await signUp({
        formFields: [
            {
                id: 'email',
                value: email.trim(),
            },
            {
                id: 'password',
                value: password.trim(),
            },
            {
                id: 'uid',
                value: uid,
            },
            {
                id: 'cid',
                value: cid ?? '',
            },
            {
                //hay que mandarlos igual, por más que sea opcional
                id: 'is_sadmin',
                value: '',
            },
        ],
    });

    switch (response.status) {
        case 'OK': {
            //TODO: devolver el usuario que vino del back
            return { status: 'ok' };
        }
        case 'FIELD_ERROR': {
            return {
                status: 'error',
                message: 'FORM_ERRORS',
                formErrors: TransformSuperTokensFormErrors(response.formFields),
            };
        }
        default: {
            throw new Error('Unkown response', { cause: response });
        }
    }
}
