import { NavLink, ScrollArea } from '@mantine/core';
import { type JSX } from 'react';
import makeMatcher from 'wouter/matcher';
import { useMenuStyles } from './UseMenuStyles.tsx';
import { useLocation } from 'wouter';
import { Env } from '../Env.ts';

const defaultMatcher = makeMatcher();

export interface MenuItem {
    label: string;
    icon: JSX.Element;
    url?: string;
    legacyUrl?: string;
    newTab?: string;
    windowLocation?: boolean;
}

interface MenuProps {
    menuItems: MenuItem[];
}

function isActive(path: string) {
    const [match] = defaultMatcher(path, window.location.pathname);
    return match;
}

export function Menu(props: MenuProps) {
    const [, setLocation] = useLocation();
    const { classes } = useMenuStyles();
    const navLinkClassNames = {
        label: classes.menuItemLabel,
        body: classes.menuItemBody,
        root: classes.menuItemRoot,
        icon: classes.menuItemIcon,
    };
    return (
        <ScrollArea>
            {props.menuItems.map((m) => {
                if (m.newTab) {
                    return (
                        <NavLink
                            key={m.label}
                            label={m.label}
                            classNames={navLinkClassNames}
                            icon={m.icon}
                            href={m.newTab}
                            target={'_blank'}
                            component={'a'}
                        />
                    );
                }

                if (m.legacyUrl) {
                    const href = `${Env.LEGACY_DOMAIN}${m.legacyUrl}`;
                    return (
                        <NavLink
                            key={m.label}
                            label={m.label}
                            classNames={navLinkClassNames}
                            icon={m.icon}
                            // Realmente necesito este onClick? probando sin
                            // onClick={(event) => {
                            //     event.preventDefault();
                            //     if (m.legacyUrl) {
                            //         window.location.href = href;
                            //     }
                            // }}
                            href={href}
                            component={'a'}
                        />
                    );
                }

                if (m.url) {
                    return (
                        <NavLink
                            key={m.label}
                            label={m.label}
                            className={isActive(m.url) ? 'active' : undefined}
                            classNames={navLinkClassNames}
                            icon={m.icon}
                            // saco el setLocation porque se rompe mucho cuando lanzamos nuevas versiones
                            // si saco esto, anda todavía peor
                            onClick={(event) => {
                                event.preventDefault();
                                if (m.url) setLocation(m.url);
                            }}
                            href={m.url}
                            component={'a'}
                        />
                    );
                }
            })}
        </ScrollArea>
    );
}
