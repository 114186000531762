/* istanbul ignore file */

import { iconPanel, iconStatsUp } from './Icons.tsx';
import { Menu, MenuItem } from './Menu.tsx';

import { IconArrowLeft } from '@tabler/icons-react';
// import debug from 'debug';

// const log = debug('MenuAdmin');

export function MenuAdmin() {
    const menuItems: MenuItem[] = [
        {
            label: 'DASHBOARD',
            icon: iconStatsUp,
            url: '/admin/dashboard',
        },
        {
            label: 'LEGACY',
            icon: iconStatsUp,
            legacyUrl: '/admin/customers/instalaciones',
        },
        {
            label: 'BlOQUEADAS',
            icon: iconPanel,
            url: '/admin/bloqueadas',
        },
        {
            label: 'CUENTAS',
            icon: iconPanel,
            url: '/admin/cuentas',
        },
        {
            label: 'SUSCRIPCIONES',
            icon: iconPanel,
            url: '/admin/suscripciones',
        },
        {
            label: 'INVOICES',
            icon: iconPanel,
            url: '/admin/invoices',
        },
        {
            label: 'CONTRATACIONES',
            icon: iconPanel,
            url: '/admin/contrataciones',
        },
        {
            label: 'USUARIOS',
            icon: iconPanel,
            url: '/admin/usuarios',
        },
        {
            label: 'MIGRACIONES',
            icon: iconPanel,
            url: '/admin/migraciones',
        },
        {
            label: 'TERMINAR',
            icon: iconPanel,
            url: '/admin/terminarmigraciones',
        },
        {
            label: 'PLANES',
            icon: iconPanel,
            url: '/admin/planes',
        },
        {
            label: 'TRIALS',
            icon: iconPanel,
            url: '/admin/trials',
        },
        {
            label: 'COBRANZAS',
            icon: iconPanel,
            url: '/admin/cobranzas',
        },
        {
            label: 'ADMIN',
            icon: iconPanel,
            url: '/admin',
        },
        {
            label: 'SALIR',
            icon: <IconArrowLeft />,
            url: '/logout',
        },
    ];

    return <Menu menuItems={menuItems} />;
}
