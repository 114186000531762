import { useEffect } from 'react';
import Session from 'supertokens-auth-react/recipe/session';
import { useLocation } from 'wouter';
import { redirectToLegacyHome } from './Logic/Rutas.ts';
import { Center } from '@mantine/core';
import { AuthLayout } from '../Layouts/AuthLayout.tsx';

export function DefaultRoute() {
    const [, setLocation] = useLocation();
    useEffect(() => {
        void (async () => {
            if (await Session.doesSessionExist()) {
                redirectToLegacyHome();
            } else {
                setLocation('/login');
            }
        })();
    }, [setLocation]);
    return (
        <AuthLayout>
            <Center>Espere unos instántes por favor</Center>
        </AuthLayout>
    );
}
