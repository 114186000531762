import {
    Alert,
    Box,
    Button,
    Center,
    Paper,
    PasswordInput,
    Stack,
    Text,
} from '@mantine/core';
import { AuthLayout } from '../../Layouts/AuthLayout.tsx';
import { Logo } from '../../Logo.tsx';
import { ResetPasswordHook } from './ResetPasswordHook.tsx';
import { useLocation } from 'wouter';
import { IconAlertCircle } from '@tabler/icons-react';
import { ResetPasswordTokenNoValidoError } from './Api/Errors/ResetPasswordTokenNoValidoError.tsx';

export interface Values {
    password: string;
}

export function ResetPassword() {
    const { form, handle, mutation } = ResetPasswordHook();

    if (mutation.error) {
        console.log(mutation.error);
    }
    if (mutation.isSuccess) return <ResetPasswordSuccess />;

    if (mutation.error instanceof ResetPasswordTokenNoValidoError) {
        return <ResetPasswordTokenNoValido />;
    }
    return (
        <AuthLayout dontIncludePaper>
            <Paper
                p={{ base: '1em', xs: '2em' }}
                withBorder
                radius="md"
                shadow="md"
            >
                <form onSubmit={form.onSubmit(handle)}>
                    <Stack>
                        <Logo />
                        <Box>Ingresá tu nueva contraseña</Box>
                        <PasswordInput
                            size={'lg'}
                            label="Contraseña"
                            placeholder="Ingresá tu contraseña" //Al menos 8 caracteres
                            required
                            disabled={mutation.isPending}
                            {...form.getInputProps('password')}
                        />
                        <Button
                            style={
                                {
                                    // backgroundColor: Colors.azul,
                                    // color: Colors.celeste,
                                }
                            }
                            formNoValidate={true}
                            size={'lg'}
                            type={'submit'}
                            fullWidth
                            mt="xl"
                            // disabled={loading}
                            loading={mutation.isPending}
                        >
                            {'Cambiar contraseña'}
                        </Button>
                    </Stack>
                </form>

                {mutation.error && (
                    <Alert
                        // mx={'1em'}
                        icon={<IconAlertCircle size="1rem" />}
                        color="red"
                    >
                        <Text sx={{ color: 'red', fontWeight: 'bold' }}>
                            {mutation.error.message}
                        </Text>
                    </Alert>
                )}
            </Paper>
        </AuthLayout>
    );
}

function ResetPasswordSuccess() {
    const [, setLocation] = useLocation();
    return (
        <AuthLayout dontIncludePaper>
            <Paper
                p={{ base: '1em', xs: '2em' }}
                withBorder
                radius="md"
                shadow="md"
            >
                <Stack>
                    <Logo />
                    <Center>Has cambiado tu contraseña con éxito</Center>
                    <Button
                        onClick={() => {
                            setLocation('/login');
                        }}
                        size={'lg'}
                        fullWidth
                        mt="xl"
                    >
                        Volver a ingresar
                    </Button>
                </Stack>
            </Paper>
        </AuthLayout>
    );
}

function ResetPasswordTokenNoValido() {
    const [, setLocation] = useLocation();
    return (
        <AuthLayout dontIncludePaper>
            <Paper
                p={{ base: '1em', xs: '2em' }}
                withBorder
                radius="md"
                shadow="md"
            >
                <Stack>
                    <Logo />
                    <Center>
                        Ha pasado demasiado tiempo. Volvé a ingresar tu mail,
                        así que te mandamos otra vez el link
                    </Center>
                    <Button
                        onClick={() => {
                            setLocation('/resetearpassword');
                        }}
                        size={'lg'}
                        fullWidth
                        mt="xl"
                    >
                        Continuar
                    </Button>
                </Stack>
            </Paper>
        </AuthLayout>
    );
}
