import { SafeUrl } from '../../Utils/SafeUrl.ts';
import { Env } from '../../Env.ts';

export function redirectToUsuarioCreado(email: string) {
    const url = new URL('/migrated', Env.LEGACY_DOMAIN);
    url.searchParams.set('email', email);
    window.location.href = url.toString();
}

export function redirectToLegacyHome() {
    window.location.href = new URL(Env.LEGACY_DOMAIN).toString();
}

export function redirectToUrlOrLegacyHome(redirectBack?: string) {
    window.location.href = SafeUrl(redirectBack, Env.LEGACY_DOMAIN);
}
