import { useDocumentTitle } from '@mantine/hooks';
import { FormEvent, useState } from 'react';
import { FormErrors } from '@mantine/form';
import { SignIn } from '../Logic/ST/SignIn.ts';
import debug from 'debug';
import { useLocation } from 'wouter';

export interface Values {
    password: string;
    email: string;
}

export interface LoginHookReturn {
    errorMessage?: string;
    loading: boolean;
    onSubmit: (values: Values, evt: FormEvent<HTMLFormElement>) => void;
    formErrors: FormErrors;
}

const log = debug('LoginHook');

export function LoginHook(): LoginHookReturn {
    //TODO: probar con esto luego
    // const session = Session.useSessionContext();
    // console.log(session);
    useDocumentTitle('Acceder - Luker.io');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [, setLocation] = useLocation();

    // useEffect(() => {
    //     void (async () => {
    //         log('Llamando a RedirectIfLoggedIn');
    //         await RedirectIfLoggedIn();
    //     })();
    // }, []);

    /**
     * Esta función necesita ser asincrona, pero los hanlders de react debe ser sincronos
     * Entonces la separo, y la llamo abajo con void _onSubmit
     * @param values
     * @param evt
     */
    async function _onSubmit(values: Values, evt: FormEvent<HTMLFormElement>) {
        evt.preventDefault();
        log('onSubmit');
        setLoading(true);
        log('setLoading(true)');
        setErrorMessage('');
        setFormErrors({});
        try {
            log('Llamando a ST');
            const resp = await SignIn({
                email: values.email,
                password: values.password,
            });
            log('respuesta de ST %O', resp);
            if (resp.status === 'ok') {
                log('Respuesta OK, redirect a ingresando');
                setLocation('/ingresando');
                return;
            }

            if (resp.status === 'error') {
                log('Respuesta error %O', resp);
                if (resp.message === 'WRONG_CREDENTIALS') {
                    setLoading(false);
                    log('setLoading(false)');
                    setErrorMessage('Datos incorrectos');
                } else {
                    setLoading(false);
                    log('setLoading(false)');
                    setFormErrors(resp.formErrors);
                }
            } else {
                setLoading(false);
                log('setLoading(false)');
                setErrorMessage('Ha ocurrido un error');
            }
        } catch (error: unknown) {
            log('Catch al llamar a ST %O', error);
            console.error(error);
            log('setLoading(false)');
            setLoading(false);
            // if (err.isSuperTokensGeneralError === true) {
            //     // this may be a custom error message sent from the API by you.
            // }
            if (error instanceof Error) {
                setErrorMessage(`Ha ocurrido un error: ${error.message}`);
            } else {
                setErrorMessage(`Ha ocurrido un error`);
            }
        }
    }

    return {
        onSubmit: (...args) => {
            void _onSubmit(...args);
        },
        loading,
        errorMessage,
        formErrors,
    };
}
