/* eslint-disable unicorn/no-zero-fractions */
import * as Sentry from '@sentry/react';
import { CaptureContext } from '@sentry/types';
import { Env } from '../Env.ts';
import debug from 'debug';

const log = debug('sentry');

export function InitSentry() {
    const options = {
        //esto es para apagar sentry en dev
        dsn: Env.MODE === 'production' ? Env.SENTRY_DSN : undefined,
        release: Env.PACKAGE_VERSION,
        environment: import.meta.env.MODE,
        integrations: [
            // Esto agrega un header "baggage" para hacer un seguimiento, pero mi back no lo soporta. Hasta que lo haga, lo saco
            // new Sentry.BrowserTracing({
            //     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            //     tracePropagationTargets: [
            //         'localhost',
            //         'https://local.luker.io',
            //         'https://app.luker.io',
            //         'https://console.luker.io',
            //         'https://auth.luker.io',
            //     ],
            // }),
            new Sentry.Replay({
                networkDetailAllowUrls: [import.meta.env.VITE_GATEWAY_URL],
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    };
    log('sentry options: %O', options);
    Sentry.init(options);
}

export function captureException(
    error: unknown,
    captureContext?: CaptureContext
) {
    Sentry.captureException(error, captureContext);
}

export function captureMessage(
    message: string,
    captureContext?: CaptureContext
) {
    Sentry.captureMessage(message, captureContext);
}

export function configureSentryInfo(
    userId: string,
    extra: Record<string, unknown>
) {
    Sentry.setContext('user', extra);

    Sentry.setUser({
        id: userId,
    });

    log('configureSentryInfo: %O', { userId, extra });
}
